import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Image } from "semantic-ui-react";
import * as Yup from "yup";
import { axios } from "../../config/axios-config";
import routes from "../../routes";
import Auth from "../../utils/auth";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import toast from "react-hot-toast";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import useAxios from "../../lib/use-axios";
import FormikInput from "../../components/formik/formik-input";
import api from "../../api";
import loginImage from "../../assets/pictures/login.png";
import logo from "../../assets/pictures/logo.svg";
import auth from "../../utils/auth";

const LoginPage = () => {
  const [lang, setLang] = useLanguage();
  const history = useHistory();
  const { run, isLoading } = useAxios();
  const selectedContent = content[lang];
  const [showPassword, setShowPassword] = React.useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailData, setEmailData] = useState("");
  const mailName = lang === "en" ? "Next" : "التالي";
  const loading = lang === "en" ? "....loading" : "تحميل ....";

  const loginSchema = Yup.object({
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
    password: Yup.string().required(selectedContent[localizationKeys.required]),
  });

  const onLogin = (values) => {
    run(axios.post(api.auth.login, values))
      .then(({ data }) => {
        Auth.setToken({
          newAccessToken: data?.data?.token,
          newRefreshToken: data?.data?.refreshToken,
        });
        history.push(routes.educationalOffices.all);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const getEmailData = (e) => {
    setEmailData(e.target.value);
  };

  const onForgotPassword = () => {
    run(axios.post(api.forgotPassword.all, { email: emailData }))
      .then(({ data }) => {
        // console.log(data.status);
        if (data.status === true) return history.push(routes.resetPassword.all);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onForgotPassword();
  };

  useEffect(() => {
    // const loggedIn = localStorage.getItem("token");
    // console.log(auth.hasExpired())
    if (!auth.hasExpired()) {
      history.push(routes.educationalOffices.all);
    }
  }, []);

  return (
    <div>
      <div className="fixed z-20 w-full px-8 py-4 flex justify-end items-center ">
        {lang === "ar" && (
          <Button
            circular
            onClick={() => setLang("en")}
            className="flex items-center justify-center text-sm bg-transparent h-10 w-4 ring-1 ring-primary rtl:mr-6 ltr:ml-6 text-primary"
          >
            EN
          </Button>
        )}
        {lang === "en" && (
          <Button
            circular
            onClick={() => setLang("ar")}
            className="flex items-center justify-center text-sm bg-transparent h-10 w-4 ring-1 ring-primary rtl:mr-6 ltr:ml-6 text-primary"
          >
            AR
          </Button>
        )}
      </div>
      <div
        className="w-screen h-screen flex items-center justify-center bg-slate-50"
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-white rounded-lg px-4 py-8 max-w-sm flex-grow">
          <Image src={logo} className=" text-center mx-auto mb-8" />

          {sendEmail === false ? (
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={onLogin}
              className=""
            >
              {(formik) => (
                <Form
                  onSubmit={formik.handleSubmit}
                  loading={isLoading}
                  className=""
                >
                  <FormikInput
                    name="email"
                    placeholder={selectedContent[localizationKeys.email]}
                  />
                  <FormikInput
                    name="password"
                    type={showPassword === true ? "text" : "password"}
                    placeholder={selectedContent[localizationKeys.password]}
                    iconPosition="right"
                    icon={
                      showPassword === true ? (
                        <>
                          <FaEyeSlash
                            className="absolute flex items-center justify-center mt-2 rtl:left-0 rtl:ml-2 ltr:right-0 ltr:mr-2 hover:cursor-pointer text-gray-600"
                            size={20}
                            onClick={() => setShowPassword(false)}
                          />
                        </>
                      ) : (
                        <>
                          <FaEye
                            className="absolute flex items-center justify-center mt-2 text-gray-600 rtl:left-0 rtl:ml-2 ltr:right-0 ltr:mr-2 hover:cursor-pointer"
                            size={20}
                            onClick={() => setShowPassword(true)}
                          />
                        </>
                      )
                    }
                  />
                  {/* <div className="underline my-3 text-end text-[rgb(24,119,242)] cursor-pointer hover:text-blue-600">
                    <button type="" onClick={() => setSendEmail(true)}>
                      {selectedContent[localizationKeys.forgotPassword]}
                    </button>
                  </div> */}
                  <Button
                    primary
                    className="mt-8 block w-full h-12"
                    type="submit"
                  >
                    {selectedContent[localizationKeys.loginButton]}
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-lg font-medium text-gray-900 dark:text-dark"
                >
                  {lang === "en" ? "Email" : "البريد الالكتروني"}
                </label>
                <input
                  name="email"
                  placeholder={lang === "en" ? "Email" : "البريد الالكتروني"}
                  value={emailData}
                  onChange={getEmailData}
                  type="email"
                  id="email"
                  className="my-4 bg-gray-50 border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <div className="flex justify-between my-4">
                <button
                  type=""
                  className="px-4 py-2  bg-blue-400 hover:bg-blue-600 duration-300 rounded-lg  text-lg font-medium"
                >
                  {isLoading ? loading : mailName}
                </button>
                <button
                  onClick={() => setSendEmail(false)}
                  type=""
                  className=" text-blue-600 hover:text-blue-600 duration-300  text-lg font-medium underline"
                >
                  {lang === "en" ? "back to login" : "  تسجيل الدخول"} &nbsp;
                  &#8592;
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
