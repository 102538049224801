import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const useQueryParams = (defaultValues = {}) => {
  const history = useHistory();
  const { search } = useLocation();

  // Parse query params from the URL
  const parsedQuery = React.useMemo(
    () => queryString.parse(search, { arrayFormat: "bracket" }),
    [search]
  );

  // Merge default values with current query params
  const mergedQuery = React.useMemo(() => {
    return { ...defaultValues, ...parsedQuery };
  }, [defaultValues, parsedQuery]);

  // Update query params in the URL
  const updateQueryParams = (updatedParams) => {
    const newQuery = {
      ...mergedQuery,
      ...updatedParams,
    };

    // Remove any keys with undefined or empty values
    Object.keys(newQuery).forEach((key) => {
      if (
        newQuery[key] === undefined ||
        newQuery[key] === null ||
        newQuery[key] === "" ||
        (Array.isArray(newQuery[key]) && newQuery[key].length === 0)
      ) {
        delete newQuery[key];
      }
    });

    // Update the URL
    history.replace(
      `?${queryString.stringify(newQuery, { arrayFormat: "&" })}`
    );
  };

  return [mergedQuery, updateQueryParams];
};

export default useQueryParams;
