import React from "react";

function CrownIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 13.488 10.79"
    >
      <path
        fill="#6dc43f"
        d="M11.127 9.441H2.36a.338.338 0 00-.337.337v.674a.338.338 0 00.337.337h8.767a.338.338 0 00.337-.337v-.674a.338.338 0 00-.337-.337zM12.476 2.7a1.012 1.012 0 00-1.012 1.012.991.991 0 00.093.417l-1.526.915A.673.673 0 019.1 4.8L7.382 1.791a1.012 1.012 0 10-1.277 0L4.388 4.8a.674.674 0 01-.931.244l-1.524-.918a1.011 1.011 0 10-.921.594 1.033 1.033 0 00.162-.02L2.7 8.767h8.09L12.314 4.7a1.033 1.033 0 00.162.017 1.012 1.012 0 100-2.023z"
        data-name="Icon awesome-crown"
      ></path>
    </svg>
  );
}

export default CrownIcon;
