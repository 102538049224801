import localizationKeys from "./localization-keys";

const ar = {
  [localizationKeys.login]: "سجل الدخول لحسابك",
  [localizationKeys.loginButton]: "تسجيل الدخول",
  [localizationKeys.email]: "البريد الالكتروني",
  [localizationKeys.password]: "كلمة المرور",
  [localizationKeys.registration]: "إنشاء حساب",
  [localizationKeys.forgotPassword]: "هل نسيت كلمة المرور؟",
  [localizationKeys.dontHaveAnAccont]: "ليس لديك حساب؟",
  [localizationKeys.required]: "مطلوب",
  [localizationKeys.emailError]: "يجب أن يكون في صيغة إيميل",
  [localizationKeys.subjects]: "المواد الدراسية",
  [localizationKeys.educationalOffices]: "المكاتب التعليمية",
  [localizationKeys.schools]: "المدارس",
  [localizationKeys.settings]: "الإعدادات",
  [localizationKeys.logout]: "تسجيل الخروج",
  [localizationKeys.cancelButton]: "لا",
  [localizationKeys.confirmButton]: "نعم",
  [localizationKeys.logoutMessage]: "هل تريد تسجيل الخروج؟",
  [localizationKeys.admin]: "أدمن",
  [localizationKeys.educationalOfficeName]: "اسم المكتب",
  [localizationKeys.city]: "المدينة",
  [localizationKeys.action]: "الإجراء",
  [localizationKeys.addEducationalOffice]: "إضافة مكتب تعليمي جديد",
  [localizationKeys.editEducationalOffice]: "تعديل مكتب تعليمي",
  [localizationKeys.gender]: "النوع",
  [localizationKeys.male]: "بنين",
  [localizationKeys.female]: "بنات",
  [localizationKeys.cancel]: "إلغاء",
  [localizationKeys.create]: "إنشاء",
  [localizationKeys.edit]: "تعديل",
  [localizationKeys.createdSuccessfully]: "تم الإنشاء بنجاح",
  [localizationKeys.editedSuccessfully]: "تم التعديل بنجاح",
  [localizationKeys.deleteMessage]: "هل أنت متأكد من الحذف؟",
  [localizationKeys.afterDeletionMessage]: "تم الحذف بنجاح",
  [localizationKeys.deleteEducationalOffice]:
    "هل أنت متأكد من إنك تريد حذف هذا المكتب التعليمي؟",
  [localizationKeys.delete]: "حذف",
  [localizationKeys.schoolName]: "اسم المدرسة",
  [localizationKeys.educationalLevels]: "المرحلة التعليمية",
  [localizationKeys.educationalOffice]: "المكتب التعليمي",
  [localizationKeys.type]: "النوع",
  [localizationKeys.studentCategory]: "فئة الطلاب",
  [localizationKeys.watch]: "مشاهدة",
  [localizationKeys.search]: "بحث",
  [localizationKeys.subjectName]: "اسم المادة",
  [localizationKeys.tracks]: "المسار",
  [localizationKeys.deleteSubject]: "هل أنت متأكد من إنك تريد حذف هذه المادة؟",
  [localizationKeys.addSubject]: "إضافة مادة دراسية جديدة",
  [localizationKeys.editSubject]: "تعديل مادة دراسية",
  [localizationKeys.schoolType]: "نوع المدرسة",
  [localizationKeys.jobs]: "الوظائف",
  [localizationKeys.jobName]: "اسم الوظيفة",
  [localizationKeys.school]: "المدرسة",
  [localizationKeys.jobLocation]: "مكان الوظيفة",
  [localizationKeys.workHoursType]: "نوع الدوام",
  [localizationKeys.educationalLevel]: "المرحلة التعليمية",
  [localizationKeys.state]: "الحالة",
  [localizationKeys.available]: "متاحة",
  [localizationKeys.closed]: "مغلقة",
  [localizationKeys.address]: "العنوان",
  [localizationKeys.phoneNumber]: "رقم الجوال",
  [localizationKeys.schoolPublishedJobs]: "الوظائف الشاغرة",
  [localizationKeys.jobDetails]: "تفاصيل الوظيفة",
  [localizationKeys.job]: "الوظيفة",
  [localizationKeys.subject]: "المادة",
  [localizationKeys.publishedDate]: "تاريخ و وقت النشر",
  [localizationKeys.numberOfApplied]: "المتقدميين للوظيفة",
  [localizationKeys.salary]: "الراتب الشهري",
  [localizationKeys.applicant]: "متقدم",
  [localizationKeys.SR]: "ر.س",
  [localizationKeys.acceptanceInstructions]: "الشروط المطلوبة للحصول على وظيفة",
  [localizationKeys.freeTrial]: "التجربة المجانية",
  [localizationKeys.all]: "الكل",
  [localizationKeys.determinedAtTheInterview]: "يحدد عند المقابلة",
  [localizationKeys.typeOfInstructors]: "فئة المعلمين المطلوبة",
  [localizationKeys.jobDescription]: "تفاصيل الوظيفة",
  [localizationKeys.language]: "اللغة",
  [localizationKeys.english]: "الإنجليزية",
  [localizationKeys.arabic]: "العربية",
  [localizationKeys.schoolDescription]: "وصف المدرسة",
  [localizationKeys.schoolJobsNotFound]: "لا توجد وظائف شاغرة",
  [localizationKeys.notCompleted]: "غير مدخل",
  [localizationKeys.subjectsNotFound]: "لا توجد مواد دراسية",
  [localizationKeys.jobsNotFound]: "لا توجد وظائف",
  [localizationKeys.educationalOfficesNotFound]: "لا توجد مكاتب تعليمية",
  [localizationKeys.schoolsNotFound]: "لا توجد مدارس",
  [localizationKeys.commercialRegistrationNumber]: "رقم السجل التجاري",
  [localizationKeys.instructors]: "المعلمين",
  [localizationKeys.instructorName]: "اسم المعلم",
  [localizationKeys.cv]: "السيرة الذاتية",
  [localizationKeys.view]: "مشاهدة",
  [localizationKeys.track]: "المسار",
  [localizationKeys.instructorsNotFound]: "لا يوجد معلمين",
  [localizationKeys.hiddenIdentity]: "هوية مخفية",
  [localizationKeys.hiddenIdentityInfo]:
    "لا يظهر في هذا المنشور أي معلومات عن هوية المدرسة للمستخدم",
  [localizationKeys.nationality]: "الجنسية",
  [localizationKeys.englishLanguageLevel]: "مستوى اللغة الإنجليزية",
  [localizationKeys.studies]: "الدراسة",
  [localizationKeys.educationalQualification]: "المؤهل الدراسي",
  [localizationKeys.specialization]: "التخصص",
  [localizationKeys.graduationYear]: "سنة التخرج",
  [localizationKeys.trainingCourses]: "الدورات التدريبية",
  [localizationKeys.courseName]: "اسم الدورة",
  [localizationKeys.theOrganization]: "المنظمة",
  [localizationKeys.courseDuration]: "مدة الدورة",
  [localizationKeys.certificate]: "الشهادة",
  [localizationKeys.pastExperience]: "الخبرات السابقة",
  [localizationKeys.yearsOfExperience]: "سنوات الخبرة",
  [localizationKeys.ejada]: "إجادة",
  [localizationKeys.level]: "المرحلة",
  [localizationKeys.englishLanguageLevel]: "مستوى اللغة الإنجليزية",
  [localizationKeys.studies]: "الدراسة",
  [localizationKeys.educationalQualification]: "المؤهل الدراسي",
  [localizationKeys.specialization]: "التخصص",
  [localizationKeys.graduationYear]: "سنة التخرج",
  [localizationKeys.trainingCourses]: "الدورات التدريبية",
  [localizationKeys.courseName]: "اسم الدورة",
  [localizationKeys.theOrganization]: "المنظمة",
  [localizationKeys.courseDuration]: "مدة الدورة",
  [localizationKeys.certificate]: "الشهادة",
  [localizationKeys.pastExperience]: "الخبرات السابقة",
  [localizationKeys.yearsOfExperience]: "سنوات الخبرة",
  [localizationKeys.ejada]: "إجادة",
  [localizationKeys.level]: "المرحلة",
  [localizationKeys.instructor]: "معلم",
  [localizationKeys.goToEjad]: "الذهاب إلى إيجاد",

  [localizationKeys.ejad]: "إيجاد",
  [localizationKeys.login]: "سجل الدخول لحسابك",
  [localizationKeys.loginButton]: "تسجيل الدخول",
  [localizationKeys.email]: "البريد الالكتروني",
  [localizationKeys.password]: "كلمة المرور",
  [localizationKeys.registration]: "إنشاء حساب",
  [localizationKeys.forgotPassword]: "هل نسيت كلمة المرور؟",
  [localizationKeys.dontHaveAnAccont]: "ليس لديك حساب؟",
  [localizationKeys.required]: "مطلوب",
  [localizationKeys.emailError]: "يجب أن يكون في صيغة إيميل",
  [localizationKeys.name_ar]: "اسم المدرسة بالعربية",
  [localizationKeys.name_en]: "اسم المدرسة بالإنجليزية",
  [localizationKeys.phone]: "رقم الهاتف",
  [localizationKeys.confirmPassword]: "تأكيد كلمة المرور",
  [localizationKeys.alreadyHaveAnAccount]: "هل لديك حساب الفعل؟",
  [localizationKeys.signupEmailVerificationMessage]:
    "تم إرسال رابط التأكد إلى بريدك الإلكتروني ",
  [localizationKeys.phoneMustBeNumber]: "رقم الهاتف يجب أن يكون أرقام فقط",
  [localizationKeys.phoneMustBeNineCharchters]:
    "رقم الجوال يجب أن يكون 9 أرقام",
  [localizationKeys.recoverPassword]: "إسترجاع كلمة السر",
  [localizationKeys.recover]: "إسترجاع",
  [localizationKeys.verifyEmailAddressMessage]: "تم التحقق من بريدك الإلكتروني",
  [localizationKeys.verifyEmailAddress]: "التحقق من البريد الإلكتروني",
  [localizationKeys.resetPasswordMessage]: "لقد تم تغيير كلمة المرور بنجاح ",
  [localizationKeys.changePassword]: "تغيير كلمة المرور",
  [localizationKeys.checkEmailResetLink]:
    "بعد إدخال بريدك الإلكتروني, سيرسل إليك رابط لكتابة كلمة المرور الجديدة",
  [localizationKeys.welcomeMessage]: "اهلاً بك في إيجاد",
  [localizationKeys.completeProfileTitle]: "إستكمال بيانات الملف الشخصي",
  [localizationKeys.thisDataIsRequired]:
    "هذه البيانات الضرورية للإستمتاع بالمنصة",
  [localizationKeys.city]: "المدينة",
  [localizationKeys.educationalOffice]: "المكتب التعليمي",
  [localizationKeys.educationalLevels]: "المراحل التعليمية",
  [localizationKeys.schoolType]: "نوع المدرسة",
  [localizationKeys.address]: "العنوان",
  [localizationKeys.studentType]: "فئة الطلاب",
  [localizationKeys.uploadPhotos]: "تحميل الصور",
  [localizationKeys.male]: "بنين",
  [localizationKeys.female]: "بنات",
  [localizationKeys.edit]: "تعديل",
  [localizationKeys.brief]: "عن المدرسة",
  [localizationKeys.addLogo]: "إضافة الشعار",
  [localizationKeys.addImage]: "إضافة الغلاف",
  [localizationKeys.logout]: "تسجيل الخروج",
  [localizationKeys.cancelButton]: "لا",
  [localizationKeys.confirmButton]: "نعم",
  [localizationKeys.logoutMessage]: "هل تريد تسجيل الخروج؟",
  [localizationKeys.school]: "المدرسة",
  [localizationKeys.vacancies]: "الوظائف",
  [localizationKeys.opened]: "متاحة",
  [localizationKeys.closed]: "مغلقة",
  [localizationKeys.emailVerification]: "تأكيد الحساب",
  [localizationKeys.emailVerificationText]:
    "لإستمرار عملية تأكيد الحساب يرجى تأكيد حسابك من خلال الرابط الذي تم إرساله إلى بريدك الإلكتروني",
  [localizationKeys.pleaseCompleteYourProfile]: "يرجى إكمال ملفك الشخصي أولاً",
  [localizationKeys.pleaseCompleteYourProfileText]:
    "لمواصلة استخدام إيجاد والتمتع بخدماته يرجى إكمال بيانات الملف الشخصي",
  [localizationKeys.goToProfile]: "الذهاب إلى الملف الشخصي",
  [localizationKeys.save]: "حفظ",
  [localizationKeys.delete]: "حذف",
  [localizationKeys.updatedSuccessfully]: "تم التعديل بنجاح",
  [localizationKeys.create]: "إضافة",
  [localizationKeys.gender]: "النوع",
  [localizationKeys.educationalOfficeName]: "اسم المكتب",
  [localizationKeys.addEducationalOffice]: "إضافة مكتب تعليمي جديد",
  [localizationKeys.createdSuccessfully]: "تم الإضافة بنجاح",
  [localizationKeys.cancel]: "إلغاء",
  [localizationKeys.mainPage]: "الرئيسية",
  [localizationKeys.YouHave]: "متبقي لك",

  [localizationKeys.addNewVacancy]: "أضف منشور وظيفي جديد",
  [localizationKeys.pastPublications]: "المنشورات السابقة",
  [localizationKeys.thereAreNoPublications]: "لا توجد وظائف منشورة حتى الآن.",
  [localizationKeys.drafted]: "مسودة",
  [localizationKeys.active]: "متاحة",
  [localizationKeys.archived]: "مغلقة",
  [localizationKeys.eduLevel]: "المرحلة التعليمية",
  [localizationKeys.track]: "المسار التعليمي",
  [localizationKeys.typeOfInstructors]: "فئة المعلمين المطلوبة",
  [localizationKeys.salary]: "الراتب الشهري",
  [localizationKeys.RS]: "ر.س",
  [localizationKeys.determinedAtTheInterview]: "يحدد عند المقابلة",
  [localizationKeys.more]: "المزيد",
  [localizationKeys.vacancyDetails]: "تفاصيل الوظيفة",
  [localizationKeys.vacancy]: "الوظيفة",
  [localizationKeys.subject]: "المادة",
  [localizationKeys.educationalLevel]: "المرحلة التعليمية",
  [localizationKeys.publishedDate]: "تاريخ النشر",
  [localizationKeys.numberOfApplied]: "المتقدميين للوظيفة",
  [localizationKeys.applicant]: "متقدم",
  [localizationKeys.jobDescription]: "تفاصيل الوظيفة",
  [localizationKeys.deleteVacancy]:
    "هل أنت متأكد من أنك تريد حذف هذا المنشور الوظيفي؟",
  [localizationKeys.yesDeleteVacancy]: "حذف المنشور",
  [localizationKeys.afterDeletionMessage]: "تم الحذف بنجاح",
  [localizationKeys.no]: "لا",
  [localizationKeys.deleteVacancyNote]:
    "في حالة حذفك لمنشور وظيفي معين تحذف كل البيانات المتعلقة به نهائياً",
  [localizationKeys.closeVacancyTitle]:
    "هل أنت متأكد من أنك تريد إغلاق هذا المنشور الوظيفي؟",
  [localizationKeys.closeVacancyNote]:
    "في حالة إغلاقك لمنشور وظيفي معين لا يمكن إعادته متاحاً مرة أخرى نهائياً",
  [localizationKeys.vacancyIsClosedSuccessfully]:
    "تم غلق المنشور الوظيفى بنجاح",
  [localizationKeys.yesCloseVacancy]: "إغلاق المنشور",
  [localizationKeys.addNewJobPosting]: "إضافة منشور وظيفي جديد",
  [localizationKeys.stepOne]: "الخطوة الأولى",
  [localizationKeys.stepTwo]: "الخطوة الثانية",
  [localizationKeys.jobName]: "اسم الوظيفة",
  [localizationKeys.description]: "الوصف",
  [localizationKeys.workingPlace]: "مكان الدوام",
  [localizationKeys.workingTime]: "وقت الدوام",
  [localizationKeys.saveDraft]: "حفظ في المسودات",
  [localizationKeys.publish]: "نشر",
  [localizationKeys.editJobPosting]: "تعديل المنشور وظيفي ",
  [localizationKeys.descriptionPlaceHolder]: "اكتب تفاصيل أكتر عن الوظيفة",
  [localizationKeys.female2]: "إناث",
  [localizationKeys.next]: "التالي",
  [localizationKeys.noResultMessage]: "لا يوجد",
  [localizationKeys.fileName]: "اسم الملف",
  [localizationKeys.employmentApplications]: "طلبات التوظيف",
  [localizationKeys.jobs]: "الوظائف",
  [localizationKeys.drafted2]: "المسودات",
  [localizationKeys.active2]: "المتاحة",
  [localizationKeys.archived2]: "المغلقة",
  [localizationKeys.paying]: "المدفوعات",
  [localizationKeys.settings]: "الإعدادت",
  [localizationKeys.language]: "اللغة",
  [localizationKeys.arabic]: "العربية",
  [localizationKeys.english]: "الإنجليزية",
  [localizationKeys.noDraftedJobs]: "لا توجد وظائف في المسودات حتى الآن.",
  [localizationKeys.noClosedJobs]: "لا توجد وظائف مغلقة حتى الآن.",
  [localizationKeys.applied]: "متقدم",
  [localizationKeys.cv]: "السيرة الذاتية",
  [localizationKeys.status]: "الحالة",
  [localizationKeys.actions]: "الإجراء",
  [localizationKeys.all]: "الكل",
  [localizationKeys.view]: "مشاهدة",
  [localizationKeys.isViewed]: "المعروضة سابقا",
  [localizationKeys.isNotViewed]: "غير المعروضة سابقا",
  [localizationKeys.download]: "تحميل",
  [localizationKeys.underReview]: "قيد المراجعة",
  [localizationKeys.approved]: "مقبول",
  [localizationKeys.notApproved]: "مرفوض",
  [localizationKeys.approve]: "قبول",
  [localizationKeys.decline]: "رفض",
  [localizationKeys.approvingTitle]:
    "هل أنت متأكد من أنك تريد قبول طلب هذا المعلم",
  [localizationKeys.approvingBody]:
    "في حالة تأكيد القبول لا يمكنك التراجع عن هذا الإجراء مرة اخرى",
  [localizationKeys.rejectingTitle]:
    "هل أنت متأكد من أنك تريد رفض طلب هذا المعلم ",
  [localizationKeys.rejectingBody]:
    "في حالة تأكيد الرفض لا يمكنك التراجع عن هذا الإجراء مرة اخرى",
  [localizationKeys.approvedSuccessfully]: "تم القبول بنجاح",
  [localizationKeys.rejectedSuccessfully]: "تم الرفض بنجاح",
  [localizationKeys.freeTrial]: "التجربة المجانية",
  [localizationKeys.noEmploymentApplications]: "لا يوجد طلبات التوظيف",
  [localizationKeys.profileIsCompletedSuccessfully]:
    "تم إستكمال الملف الشخصي بنجاح",
  [localizationKeys.editLogo]: "تغيير الشعار",
  [localizationKeys.editImage]: "تغيير الصورة",
  [localizationKeys.publishedSuccessfully]: "تم النشر بنجاح",
  [localizationKeys.publishVacancyTitle]:
    "هل أنت متأكد إنك تريد نشر هذا المنشور؟",
  [localizationKeys.loading]: "يتم التحميل",
  [localizationKeys.failedMessage]:
    "نأسف لم تكتمل عملية الدفع بنجاح للسبب الأتي",
  [localizationKeys.returnHome]: "العودة إلى الرئيسية",
  [localizationKeys.errorCode]: "سبب رقم",
  [localizationKeys.okUnderstand]: "حسناً، فهمت",
  [localizationKeys.MF001]:
    "فشلت المصادقة (قد يكون بسبب إدخال معلومات خاطئة ككلمة المرور أو اسم حامل البطاقة أو مشاكل تقنية لدى المصرف)",
  [localizationKeys.MF002]:
    "تم رفض العملية من قبل المصرف (قد يكون بسبب إدخال معلومات غير صالحة حول البطاقة أو المبلغ في حسابك غير كافي أو رفضه بسبب إحتمالية الخطر أو أن البطاقة ممنتهية الصلاحية أو تم إيقافها أو غير مصرح لها بالدفع أونلاين)",
  [localizationKeys.MF003]:
    "م حجب العملية من قبل بوابة الدفع (قد يكون ذلك بسبب BIN بطاقة غير مدعومة أو الكشف عن إحتيال او قواعد الحجب للأمان)",
  [localizationKeys.MF004]: "المبلغ في حسابك غير كافي",
  [localizationKeys.MF005]: "انتهى وقت الجلسة",
  [localizationKeys.MF006]: "تم إلغاء العملية",
  [localizationKeys.MF007]: "البطاقة منتهية الصلاحية",
  [localizationKeys.MF008]: "مُصدر البطاقة لا يستجيب",
  [localizationKeys.MF009]: "مرفوض لاحتمالية الخطر",
  [localizationKeys.MF010]: "رمز أمان غير صحيح",
  [localizationKeys.MF020]: "خطأ غير محدد",
  [localizationKeys.vacancyPost]: "المنشور الوظيفي",
  [localizationKeys.dateAndTime]: "التاريخ والوقت",
  [localizationKeys.price]: "المبلغ",
  [localizationKeys.noSubscribtionsFound]: "لا توجد عمليات دفع سابقة",
  [localizationKeys.deletedAccount]: "حساب محذوف",
  [localizationKeys.noJobApplicationsInThisJob]:
    "لا توجد طلبات توظيف على هذه الوظيفة",
  [localizationKeys.resetPassword]: "إعادة تعيين كلمة المرور",
  [localizationKeys.submit]: "إرسال",
  [localizationKeys.addNewEducationalOffice]: "إضافة مكتب جديد",
  [localizationKeys.agreeToAllTermsAndConditions]:
    "أوافق على جميع الشروط والأحكام",
  [localizationKeys.send]: "إرسال",
  [localizationKeys.forHelp]: "لطلب المساعدة",
  [localizationKeys.followUsOn]: "تابعونا على",
  [localizationKeys.callUs]: "اتصل بنا",
  [localizationKeys.userGuide]: "دليل المستخدم",
  [localizationKeys.footer]:
    "باستخدامك هذا الموقع، فإنك توافق على شروط الخدمة وسياسة الخصوصية وحقوق النشر، جميع الحقوق محفوظة لإيجاد، المملكة العربية السعودية ",
  [localizationKeys.aboutUs1]:
    "إيجاد هو وسيط بين المدارس التي ترغب في توظيف مُعلمين، وبين المعلمين الراغبين في الحصول على تلك الوظائف.",
  [localizationKeys.aboutUs2]:
    "يمكنك عرض جميع الوظائف المتاحة لديك عبر الموقع الخاص بتطبيق إيجاد.",
  [localizationKeys.noInternet]: "لا يوجد إتصال بشبكة الإنترنت",
  [localizationKeys.services]: "الخدمات",
  [localizationKeys.aboutUs]: "معلومات عنا",
  [localizationKeys.contactUs]: "تواصل معنا",
  [localizationKeys.ourServices]: "خدماتنا",
  [localizationKeys.ourServicesBody]:
    "موقع إيجاد يوفر العديد من الخدمات لتوفير أفضل تجربة لمدرستك في توظيف مُعلمين مميزين، نوفر لك جميع الوسائل للحصول على النتائج المطلوبة في تعيين أكفأ المُعلمين والمُعلمات لجميع المراحل التعليمية بالسعودية",
  [localizationKeys.service1Title]: "تواصل مع المتقدمين",
  [localizationKeys.service1Body]:
    "تستطيع التواصل مع المعلمين المتقدمين مباشرة وتحديد مواعيد المقابلات الشخصية ",
  [localizationKeys.service2Title]: "نشر الوظائف المتاحة",
  [localizationKeys.service2Body]:
    "يمكنك نشر عدد غير محدود من الوظائف المتاحة بمدرستك مع توضيح المتطلبات",
  [localizationKeys.service3Title]: "استقبل عروض للتوظيف",
  [localizationKeys.service3Body]:
    "ستتلقى طلبات للحصول على الوظائف من أمهر مُعلمي السعودية مع السير الذاتية",
  [localizationKeys.service4Title]: "قم بقبول/رفض الطلبات",
  [localizationKeys.service4Body]:
    "يمكنك قبول أو رفض طلبات التوظيف من خلال الموقع الخاص بتطبيق إيجاد",
  [localizationKeys.mockupTitle]: "تطبيق إيجاد للمُعلمين",
  [localizationKeys.mockupBody]:
    "تطبيق إيجاد هو وسيط بين المدارس التي ترغب في توظيف مُعلمين، وبين المعلمين الراغبين في الحصول على تلك الوظائف",
  [localizationKeys.mockupBody2]: "حمل التطبيق واحصل على وظيفتك الآن",
  [localizationKeys.getItFrom]: "احصل عليه من",
  [localizationKeys.downloadFrom]: "تنزيل من",
  [localizationKeys.aboutEjad]: "عن إيجاد",
  [localizationKeys.aboutEjadBody]:
    "إيجاد هي منصة لتسهيل عملية التوظيف وخلق حلقة وصل بين المدارس و المعلمين في المملكة حيث تستطيع المدارس نشر الوظائف المتاحة لديها في جميع التخصصات والمراحل الدراسية، كما يستطيع المعلم التقديم بضغطة زر على الوظائف المتاحة في تخصصه",
  [localizationKeys.contactUsBody]:
    "إذا كان لديك أي اقتراحات أو استفسارات لا تتردد في التواصل معنا الآن",
  [localizationKeys.writeHere]: "أكتب هنا...",
  [localizationKeys.fullName]: "الاسم بالكامل",
  [localizationKeys.landingPageTitle]: "وظف المُعلمين الأفضل بالسعودية الآن",
  [localizationKeys.landingPageBody]:
    "عبر موقع إيجاد يمكنك عرض كل الوظائف المتاحة بمدرستك في كل التخصصات والمراحل التعليمية، ومن ثم الحصول على طلبات للحصول على تلك الوظائف من أفضل المُعلمين والمُعلمات بالمملكة العربية السعودية",
  [localizationKeys.emailIsNotVerified]: "لم يتم تفعيل الحساب",
  [localizationKeys.verifyEmailThroughThisLink]:
    "إضغط على الزر ليصلك رابط التفعيل علي البريد الإلكتروني",
  [localizationKeys.linkIsSentSuccessfully]: "تم إرسال الرابط بنجاح",
  [localizationKeys.privacyPolicyTitle]: "سياسة الخصوصية ",
  [localizationKeys.privacyPolicyBody]:
    " في تطبيق إيجاد -يمكن الوصول إليه من https://ejad.app - إحدى أولوياتنا الرئيسية هي خصوصية زوارنا. تحتوي وثيقة سياسة الخصوصية هذه على أنواع المعلومات التي يتم جمعها وتسجيلها بواسطة تطبيق إيجاد وكيف نستخدمها. إذا كانت لديك أسئلة إضافية أو تحتاج إلى مزيد من المعلومات حول سياسة الخصوصية الخاصة بنا، فلا تتردد في الاتصال بنا.تنطبق سياسة الخصوصية هذه فقط على أنشطتنا عبر الإنترنت وهي صالحة لزوار موقعنا الإلكتروني فيما يتعلق بالمعلومات التي شاركوها و / أو يجمعونها في ejad.app. لا تنطبق هذه السياسة على أي معلومات يتم جمعها في وضع عدم الاتصال أو عبر قنوات أخرى غير هذا الموقع. تم إنشاء سياسة الخصوصية الخاصة بنا بمساعدة منشئ سياسة الخصوصية المجانية.",
  [localizationKeys.contestTitle]: "موافقة ",
  [localizationKeys.contestBody]:
    "باستخدام موقعنا، فإنك بذلك توافق على سياسة الخصوصية الخاصة بنا وتوافق على شروطها. ",
  [localizationKeys.informationWeCollectTitle]: "المعلومات التي نجمعها ",
  [localizationKeys.informationWeCollectBody]:
    "سيتم توضيح المعلومات الشخصية التي يُطلب منك تقديمها، وأسباب مطالبتك بتقديمها، في الوقت الذي نطلب منك فيه تقديم معلوماتك الشخصية. إذا اتصلت بنا مباشرة، فقد نتلقى معلومات إضافية عنك مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك ومحتويات الرسالة و / أو المرفقات التي قد ترسلها إلينا وأي معلومات أخرى قد تختار تقديمها. عند التسجيل للحصول على حساب، قد نطلب معلومات الاتصال الخاصة بك، بما في ذلك عناصر مثل الاسم واسم الشركة والعنوان وعنوان البريد الإلكتروني ورقم الهاتف. ",
  [localizationKeys.howWeUseYourInformationTitle]: "كيف نستخدم معلوماتك",
  [localizationKeys.howWeUseYourInformationBody]:
    "نستخدم المعلومات التي نجمعها بطرق مختلفة، بما في ذلك:توفير وتشغيل وصيانة موقعنا، تحسين وتخصيص وتوسيع موقعنا، فهم وتحليل كيفية استخدامك لموقعنا، تطوير منتجات وخدمات وميزات ووظائف جديدة، لتواصل معك، إما بشكل مباشر أو من خلال أحد شركائنا، بما في ذلك لخدمة العملاء، لتزويدك بالتحديثات والمعلومات الأخرى المتعلقة بالموقع ولأغراض تسويقية وترويجية،إرسال لك رسائل البريد الإلكتروني،البحث عن الاحتيال ومنعه",
  [localizationKeys.logFilesTitle]: "ملفات الدخول ",
  [localizationKeys.logFilesBody]:
    "تقوم جميع شركات الاستضافة بذلك وجزء من تحليلات خدمات الاستضافة. تتضمن المعلومات التي يتم جمعها بواسطة ملفات الدخول عناوين بروتوكول الإنترنت (IP) ونوع المستعرض وموفر خدمة الإنترنت (ISP) وختم التاريخ والوقت وصفحات الإحالة / الخروج وربما عدد النقرات. هذه ليست مرتبطة بأي معلومات لتحديد الهوية الشخصية. الغرض من المعلومات هو تحليل الاتجاهات وإدارة الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات الديموغرافية. ",
  [localizationKeys.cookiesAndWebBeaconsTitle]:
    "ملفات تعريف الارتباط وإشارات الويب ",
  [localizationKeys.joinedDate]: "تاريخ التسجيل",

  [localizationKeys.cookiesAndWebBeaconsBody]:
    "مثل أي موقع آخر، يستخدم ejad.app ملفات تعريف الارتباط. تُستخدم ملفات تعريف الارتباط هذه لتخزين المعلومات بما في ذلك تفضيلات الزوار والصفحات الموجودة على موقع الويب التي وصل إليها الزائر أو زارها. تُستخدم المعلومات لتحسين تجربة المستخدمين من خلال تخصيص محتوى صفحة الويب الخاصة بنا بناءً على نوع متصفح الزوار و / أو معلومات أخرى.",
  [localizationKeys.advertisingPartnersPrivacyPoliciesTitle]:
    "سياسات خصوصية شركاء الإعلانات",
  [localizationKeys.advertisingPartnersPrivacyPoliciesBody]:
    " يمكنك الرجوع إلى هذه القائمة للعثور على سياسة الخصوصية لكل من شركاء الإعلان في ejad.app. تستخدم خوادم الإعلانات الخارجية أو شبكات الإعلانات تقنيات مثل ملفات تعريف الارتباط أو جافا سكريبت أو إشارات الويب المستخدمة في الإعلانات والروابط الخاصة بكل منها والتي تظهر على ejad.app، والتي يتم إرسالها مباشرة إلى متصفح المستخدمين. يتلقون عنوان IP الخاص بك تلقائيًا عند حدوث ذلك. تُستخدم هذه التقنيات لقياس فعالية حملاتهم الإعلانية و / أو لتخصيص محتوى الإعلان الذي تراه على مواقع الويب التي تزورها.",
  [localizationKeys.thirdPartyPrivacyPoliciesTitle]:
    "سياسات خصوصية الطرف الثالث ",
  [localizationKeys.thirdPartyPrivacyPoliciesBody]:
    " لا تنطبق سياسة الخصوصية الخاصة بـ ejad.app على المعلنين أو المواقع الإلكترونية الأخرى. وبالتالي، فإننا ننصحك بالرجوع إلى سياسات الخصوصية الخاصة بخوادم إعلانات الجهات الخارجية للحصول على معلومات أكثر تفصيلاً. قد يتضمن ممارساتهم وتعليماتهم حول كيفية الانسحاب من بعض الخيارات. يمكنك اختيار تعطيل ملفات تعريف الارتباط من خلال خيارات المتصفح الفردية الخاصة بك. لمعرفة المزيد من المعلومات التفصيلية حول إدارة ملفات تعريف الارتباط مع متصفحات الويب المحددة، يمكن العثور عليها في مواقع الويب الخاصة بالمتصفحات. ",
  [localizationKeys.CCPAPrivacyRightsTitle]:
    "حقوق خصوصية CCPA (لا تبيع معلوماتي الشخصية) ",
  [localizationKeys.CCPAPrivacyRightsBody]:
    "بموجب قانون حماية خصوصية المستهلك في كاليفورنيا، من بين حقوق أخرى، يحق للمستهلكين في كاليفورنيا: اطلب من الشركة التي تجمع البيانات الشخصية للمستهلك أن تكشف عن فئات وأجزاء معينة من البيانات الشخصية التي جمعتها الشركة عن المستهلكين. اطلب من شركة ما حذف أي بيانات شخصية عن المستهلك جمعتها الشركة. اطلب من الشركة التي تبيع البيانات الشخصية للمستهلك، ألا تبيع البيانات الشخصية للمستهلك. إذا قمت بتقديم طلب، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق، يرجى الاتصال بنا. ",
  [localizationKeys.GDPRDataProtectionRightsTitle]: "GDPR حقوق حماية بيانات",
  [localizationKeys.GDPRDataProtectionRightsBody]:
    "نود التأكد من أنك على دراية كاملة بجميع حماية البيانات الخاصة بك، على الحقوق. يحق لكل مستخدم ما يلي: الحق في الوصول - يحق لك طلب نسخ من بياناتك الشخصية. قد نفرض عليك رسومًا رمزية مقابل هذه الخدمة. الحق في التصحيح - يحق لك أن تطلب منا تصحيح أي معلومات تعتقد أنها غير دقيقة. لديك أيضًا الحق في طلب إكمال المعلومات التي تعتقد أنها غير كاملة. الحق في المسح - يحق لك طلب مسح بياناتك الشخصية، في ظل ظروف معينة. الحق في تقييد المعالجة - يحق لك طلب تقييد معالجة بياناتك الشخصية، في ظل ظروف معينة. الحق في الاعتراض على المعالجة - يحق لك الاعتراض على معالجتنا لبياناتك الشخصية، في ظل ظروف معينة. الحق في نقل البيانات - يحق لك أن تطلب منا نقل البيانات التي جمعناها إلى مؤسسة أخرى، أو إليك مباشرةً، في ظل ظروف معينة. إذا قمت بتقديم طلب، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق، يرجى الاتصال بنا ",
  [localizationKeys.ChildrensInformationTitle]: "معلومات الأطفال ",
  [localizationKeys.ChildrensInformationBody]:
    "جزء آخر من أولوياتنا هو إضافة الحماية للأطفال أثناء استخدام الإنترنت. نشجع الآباء والأوصياء على مراقبة نشاطهم عبر الإنترنت والمشاركة فيه و / أو مراقبته وتوجيهه. لا يجمع ejad.app عن قصد أي معلومات تعريف شخصية من الأطفال دون سن 13 عامًا. إذا كنت تعتقد أن طفلك قدم هذا النوع من المعلومات على موقعنا، فنحن نشجعك بشدة على الاتصال بنا على الفور وسنبذل قصارى جهدنا من أجل إزالة هذه المعلومات على الفور من سجلاتنا.",
  [localizationKeys.phoneNumberPlaceHolder]: "xxx xxx xx",
  [localizationKeys.hideSchoolIdentity]: "إخفاء هوية المدرسة",
  [localizationKeys.agreeToAll]: "أوافق على جميع",
  [localizationKeys.termsAndConditions]: "الشروط والأحكام",
  [localizationKeys.notificationsNotFound]: " لا يوجد إشعارات حتى الآن",
  [localizationKeys.now]: "الآن",
  [localizationKeys.from]: "من قبل",
  [localizationKeys.viewAll]: "عرض الكل",
  [localizationKeys.ago]: "منذ",
  [localizationKeys.minute]: "دقيقة",
  [localizationKeys.hour]: "ساعة",
  [localizationKeys.notifications]: "الإشعارات",
  [localizationKeys.notification]: "الإشعار",
  [localizationKeys.body]: "المحتوى",
  [localizationKeys.hideIdentityTitle]: "إخفاء الهوية",
  [localizationKeys.hideIdentityBody]:
    "عند تفعيلك لخاصية إخفاء هوية المدرسة لا تظهر البيانات [اسم المدرسة - العنوان - الشعار] لدى المستخدم",
  [localizationKeys.hiddenIdentity]: "هوية مخفية",
  [localizationKeys.hiddenIdentityInfo]:
    "لا يظهر في هذا المنشور أي معلومات عن هوية المدرسة للمستخدم",
  [localizationKeys.instructor]: "معلم",
  [localizationKeys.instructors]: "المعلمون",
  [localizationKeys.instructorsPlural]: "معلمين",
  [localizationKeys.englishLanguageLevel]: "مستوى اللغة الإنجليزية",
  [localizationKeys.studies]: "الدراسة",
  [localizationKeys.educationalQualification]: "المؤهل الدراسي",
  [localizationKeys.specialization]: "التخصص",
  [localizationKeys.graduationYear]: "سنة التخرج",
  [localizationKeys.trainingCourses]: "الدورات التدريبية",
  [localizationKeys.pastTrainingCourses]: "الدورات السابقة",
  [localizationKeys.courseName]: "اسم الدورة",
  [localizationKeys.theOrganization]: "المنظمة",
  [localizationKeys.courseDuration]: "مدة الدورة",
  [localizationKeys.certificate]: "الشهادة",
  [localizationKeys.pastExperience]: "الخبرات السابقة",
  [localizationKeys.schoolName]: "اسم المدرسة",
  [localizationKeys.yearsOfExperience]: "سنوات الخبرة",
  [localizationKeys.filterBySubject]: "تصفية بالمادة",
  [localizationKeys.instructorName]: "اسم المعلم",
  [localizationKeys.nationality]: "الجنسية",
  [localizationKeys.remainLimitPopup]:
    "متبقي لك عرض {X} ملفات شخصية، تكلفة عرض الملف الشخصي فيما بعد {NUM} ريال سعودي",
  [localizationKeys.payToView]: "ادفع {X} ريال سعودي لعرض ملف المعلم بالكامل",
  [localizationKeys.gotEjadCertificate]: "حاصل على إحدى دورات من إيجاد",
  [localizationKeys.wantToBuy]: "هل تريد شراء بيانات المعلم؟",
  [localizationKeys.notPermittedWithoutBuy]:
    "لا يمكن عرض بيانات المعلم بدون رسوم",
  [localizationKeys.searchBySubject]: "ابحث باسم المادة",
  [localizationKeys.reset]: "إعادة تعيين",
  [localizationKeys.filter]: "تصنيف",
  [localizationKeys.mobileNumber]: "رقم الجوال",
  [localizationKeys.specializationDegree]: "الدرجة العلمية",
  [localizationKeys.specialization]: "التخصص",
  [localizationKeys.courseOrganization]: "مكان الدورة",
  [localizationKeys.jobTitle]: "المسمى الوظيفي",
  [localizationKeys.experienceYears]: "سنوات الخبرة",
  [localizationKeys.filterByCity]: "تصفيه بالمدينه",
  [localizationKeys.searchByPageNumber]: "البحث برقم الصفحه",
  [localizationKeys.ClearFilter]: "مسح التصفيه",
  [localizationKeys.ExportFile]: "تحميل الملف",
  [localizationKeys.RegistrationDate]: "تاريخ التسجيل",
  [localizationKeys.MaterialDownloadDate]: "تاريخ تنزيل البرنامج",
  [localizationKeys.schoolInformation]: "معلومات البرنامج (كسر الحدود)",
  [localizationKeys.teachersName]: "اسم المعلم",
  [localizationKeys.teachersPhoneNumber]: "رقم هاتف المعلم",
  [localizationKeys.numberOfStudents]: "عدد الطلاب",
  [localizationKeys.numberOfBoys]: "عدد الطلاب الذكور",
  [localizationKeys.numberOfGirls]: "عدد الطلاب الاناث",
  [localizationKeys.educationalStage]: "مرحلة التعليم",
  [localizationKeys.numberOfServings]: "عدد البرامج المتاحة",
  [localizationKeys.viewAllAlbums]: "عرض جميع الصور",
  [localizationKeys.hideImage]: "اخفاء الصورة",
  [localizationKeys.showImage]: "اظهار الصورة",
  [localizationKeys.toggleVisibilityTitle]: "اخفاء الصورة ؟",
  [localizationKeys.toggleVisibilityText]: "هل تريد اخفاء الصورة؟",
  [localizationKeys.toggleVisibilityConfirm]: "نعم",
  [localizationKeys.toggleVisibilityCancel]: "لا",
  [localizationKeys.schoolFilters]: "تصفيات المدرسة",
  [localizationKeys.educationLevels]: "مراحل التعليم",
  [localizationKeys.StudentsGender]: "جنس الطلاب",
  [localizationKeys.educationStage]: "مرحلة التعليم",
  [localizationKeys.startDate]: "تاريخ البدء",
  [localizationKeys.endDate]: "تاريخ الانتهاء",
  [localizationKeys.materialStartDate]: "تاريخ تنزيل البرنامج",
  [localizationKeys.materialEndDate]: "تاريخ انتهاء البرنامج",
  [localizationKeys.SchoolType]: "نوع المدرسة",
  [localizationKeys.educationCenters]: "مركز التعليم",
  [localizationKeys.selectCities]: "اختر المدن",
  [localizationKeys.selectLevel]: "اختر المرحلة التعليمية",
  [localizationKeys.selectGender]: "اختر الجنس",
  [localizationKeys.selectStage]: "اختر المرحلة التعليمية",
  [localizationKeys.selectSchoolType]: "اختر نوع المدرسة",
  
};
export default ar;
