import React from "react";
import { Modal, Popup } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import moment from "moment";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import "moment/locale/en-au";
import { BsInfoCircle } from "react-icons/bs";

const ViewJobDetails = ({ isOpen, onClose, job, jobFromTable }) => {
  moment.locale("en-au");
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="small"
      closeOnEscape={false}
      closeIcon
      closeOnDimmerClick={false}
      className="max-w-screen-2xl "
    >
      <Modal.Content className="px-16">
        <div className="flex items-center justify-between my-2 ">
          <div></div>
          <div className="font-extrabold ">
            {selectedContent[localizationKeys.jobDetails]}
          </div>

          <div className="flex items-center">
            {job?.isSchoolNameHidden && (
              <div className="flex items-center p-1 mx-1 bg-[#E6E6E6] text-[#909090] rounded-3xl font-normal text-sm">
                {selectedContent[localizationKeys.hiddenIdentity]}
                <Popup
                  trigger={<BsInfoCircle className="mx-1" />}
                  position="top right"
                  className="rounded-xl p-2 w-60 -mt-0.5 border-[#C5E6F3] before:bg-transparent  before:shadow-none"
                >
                  <Popup.Content className="text-center text-sm text-[#909090]">
                    {selectedContent[localizationKeys.hiddenIdentityInfo]}
                  </Popup.Content>
                </Popup>
              </div>
            )}
            {job?.status === "archived" ? (
              <span className="text-[#D10000] bg-red-100 p-1 px-4 rounded-xl">
                {selectedContent[localizationKeys.closed]}
              </span>
            ) : (
              <span className="text-[#34C00A] bg-[#34C00A1F] p-1 px-4 rounded-xl">
                {selectedContent[localizationKeys.available]}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div>
            <div className="grid grid-cols-2 mt-2">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.job]}:
              </span>
              <span>{job?.title}</span>
            </div>
            <div className="grid grid-cols-2 mt-2">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.subject]}:
              </span>
              <span>{job?.subject?.name}</span>
            </div>
            <div className="grid grid-cols-2 mt-2">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.educationalLevel]}:
              </span>
              <span>{job?.education_levels?.name}</span>
            </div>
            {jobFromTable && (
              <div className="grid grid-cols-2 mt-2">
                <span className="text-greyText rtl:ml-2 ltr:mr-2">
                  {selectedContent[localizationKeys.school]}:
                </span>
                <span
                  className="underline hover:cursor-pointer"
                  onClick={() =>
                    history.push(routes.schools.profile(job?.school?._id))
                  }
                >
                  {job?.school?.name?.ar}
                </span>
              </div>
            )}
            <div className="grid grid-cols-2 mt-2">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.typeOfInstructors]}:
              </span>
              <span>{job?.gender}</span>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 mt-2 w-max">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.publishedDate]}:
              </span>
              <span className="flex items-center gap-1">
                <span>{moment(job.createdAt).format("L")}</span>,
                <span>{moment(job.createdAt).format("LT")}</span>
              </span>
            </div>
            <div className="grid grid-cols-2 mt-2">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.numberOfApplied]}:
              </span>
              <span>
                <span>{job?.applied_count}</span>
                <span className="mx-1">
                  {selectedContent[localizationKeys.applicant]}
                </span>
              </span>
            </div>
            <div className="grid grid-cols-2 mt-2">
              <span className="text-greyText rtl:ml-2 ltr:mr-2">
                {selectedContent[localizationKeys.salary]}:
              </span>
              {job?.salary ? (
                <span>
                  <span>{job?.salary}</span>
                  <span className="mx-1">
                    {selectedContent[localizationKeys.SR]}
                  </span>
                </span>
              ) : (
                <span>
                  {selectedContent[localizationKeys.determinedAtTheInterview]}
                </span>
              )}
            </div>
            {jobFromTable && (
              <div className="grid grid-cols-2 mt-2">
                <span className="text-greyText rtl:ml-2 ltr:mr-2">
                  {selectedContent[localizationKeys.city]}:
                </span>
                <span>{job?.city?.name}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="flex rounded-xl bg-[#F4F0FF] w-max text-primary justify-center items-center h-8 px-2 rtl:ml-2 ltr:mr-2">
            {lang === "ar"
              ? job?.work_type?.name?.ar
              : job?.work_type?.name?.en}
          </div>
          <div className="flex rounded-xl bg-[#F4F0FF] w-max text-primary justify-center items-center h-8 px-2 mx-2">
            {lang === "ar"
              ? job?.work_place?.name?.ar
              : job?.work_place?.name?.en}
          </div>
        </div>
        <hr className="my-4" />
        <div className="font-extrabold">
          {selectedContent[localizationKeys.jobDescription]}
        </div>
        <div className="mt-2 mb-8"> {job?.description}</div>
      </Modal.Content>
    </Modal>
  );
};

export default ViewJobDetails;
