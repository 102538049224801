import React from "react";

function PdfIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18.323 20.941"
    >
      <g>
        <path
          fill="#e2e5e7"
          d="M97.309 0A1.313 1.313 0 0096 1.309v18.323a1.313 1.313 0 001.309 1.309H110.4a1.313 1.313 0 001.309-1.309V5.235L106.47 0z"
          data-name="Path 17173"
          transform="translate(-93.382)"
        ></path>
        <path
          fill="#b0b7bd"
          d="M353.309 5.235h3.926L352 0v3.926a1.313 1.313 0 001.309 1.309z"
          data-name="Path 17174"
          transform="translate(-338.912)"
        ></path>
        <path
          fill="#cad1d8"
          d="M387.926 131.926L384 128h3.926z"
          data-name="Path 17175"
          transform="translate(-369.603 -122.765)"
        ></path>
        <path
          fill="#f15642"
          d="M47.706 247.2a.656.656 0 01-.654.654h-14.4A.656.656 0 0132 247.2v-6.544a.656.656 0 01.654-.654h14.4a.656.656 0 01.654.654z"
          data-name="Path 17176"
          transform="translate(-32 -230.184)"
        ></path>
        <g
          fill="#fff"
          data-name="Group 22308"
          transform="translate(2.853 12.038)"
        >
          <path
            d="M101.744 294.681a.36.36 0 01.355-.361h1.209a1.308 1.308 0 010 2.616h-.874v.691a.328.328 0 01-.335.361.353.353 0 01-.355-.361zm.69.3v1.3h.874a.652.652 0 000-1.3z"
            data-name="Path 17177"
            transform="translate(-101.744 -294.32)"
          ></path>
          <path
            d="M188.185 299.093a.327.327 0 01-.361-.324V295.8a.356.356 0 01.361-.325h1.2a1.811 1.811 0 01.047 3.621zm.33-2.982v2.344h.868a1.176 1.176 0 000-2.344z"
            data-name="Path 17178"
            transform="translate(-184.303 -295.425)"
          ></path>
          <path
            d="M287.821 296.137v.832h1.334a.4.4 0 01.377.371.364.364 0 01-.377.314h-1.334v1.1a.31.31 0 01-.313.324.332.332 0 01-.371-.324v-2.972a.331.331 0 01.371-.325h1.837a.326.326 0 01.366.325.362.362 0 01-.366.356h-1.523z"
            data-name="Path 17179"
            transform="translate(-279.553 -295.41)"
          ></path>
        </g>
        <path
          fill="#cad1d8"
          d="M108.434 416.654H96v.654h12.434a.656.656 0 00.654-.654V416a.656.656 0 01-.654.654z"
          data-name="Path 17180"
          transform="translate(-93.382 -398.986)"
        ></path>
      </g>
    </svg>
  );
}

export default PdfIcon;
