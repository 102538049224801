import React, { useState } from "react";
import content from "../../localization/content";

import { AiOutlineNotification } from "react-icons/ai";
import { useLanguage } from "../../lib/language-context";
import localizationKeys from "../../localization/localization-keys";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../routes";
import toast from "react-hot-toast";
import { useEffect } from "react";
function Notification({ notifications }) {
  const [isRead, setIsRead] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const { run, isLoading, data: school } = useAxios({ data: [] });
  const [flag, setFlag] = useState(false);
  const history = useHistory();
  useEffect(() => {}, []);

  const toggleNotificatoins = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const compare = (a, b) => {
    if (!a.isRead && b.isRead) {
      return -1;
    } else if (a.isRead && !b.isRead) {
      return 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {}, [flag]);

  const sortedArray = notifications.sort(compare);

  const newNotification = notifications.filter(
    (notifiy) => notifiy.isRead === false
  );

  const schoolData = async (schoolId) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    run(
      authAxios.get(
        `https://ejad-backend.onrender.com/api/v1/schools/${schoolId}`,
        config
      )
    )
      .then(({ data }) => {
        if (data.data.isDeleted === true) {
          return toast.error(
            lang === "en"
              ? "this school has been deleted"
              : "تم مسح هذه المدرسه"
          );
        } else {
          return history.push(routes.schools.profile(schoolId));
        }
      })
      .catch((e) => toast.error(e.errors[0].message));
  };

  const handler = (schoolId, id) => {
    schoolData(schoolId);
    readOneNotification(id);
  };
  const readOneNotification = async (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    run(
      authAxios.patch(
        `https://ejad-backend.onrender.com/api/v1/admins/notifications/read-one/${id}`,
        config
      )
    )
      .then(({ data }) => {})
      .catch((e) => toast.error(e.errors[0].message));
  };

  const readAllNotification = async () => {
    setIsRead(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    run(
      authAxios.patch(
        `https://ejad-backend.onrender.com/api/v1/admins/notifications/read-all`,
        config
      )
    )
      .then(({ data }) => {})
      .catch((e) => toast.error(e.errors[0].message));
  };

  return (
    <div className=" ">
      <AiOutlineNotification
        size={20}
        color="white"
        className="cursor-pointer"
        onClick={toggleNotificatoins}
      />

      {sortedArray.length > 0 ? (
        <div
          className={`${
            isNotificationOpen || isRead || newNotification.length === 0
              ? ""
              : " w-[1.4rem] h-[1.4rem] bg-red-500 text-white"
          }  absolute -top-3  -right-2 rounded-full  flex justify-center items-center`}
        >
          <span className="text-base">
            {isRead || isNotificationOpen || newNotification.length === 0
              ? ""
              : newNotification.length}
          </span>
        </div>
      ) : (
        ""
      )}

      {isNotificationOpen ? (
        <div className="min-w-[18rem]  bg-slate-100 absolute left-[0]  top-[100%] rounded-lg text-black shadow-xl z-10">
          <div className="flex justify-between items-center border-b bg-slate-100 text-sm  p-2 cursor-default ">
            <p className="font-medium text-base">
              {selectedContent[localizationKeys.notifications]}
            </p>
            <div onClick={readAllNotification}>
              <p className="underline cursor-pointer">
                {lang === "en" ? "mark all as read ?" : "تحديد الكل كمقرؤه ؟"}
              </p>
            </div>
          </div>
          <div className="p- cursor-default">
            <ul className="flex flex-col gap-2 px-2 max-h-[16rem] overflow-y-scroll no-scrollbar">
              {notifications?.map((e, index) => (
                <li
                  onClick={() => handler(e.schoolId, e.id)}
                  key={index}
                  className={`border-b mb-2 px-2 py-2 cursor-pointer hover:bg-slate-400 ${
                    e.isRead === true || isRead === true
                      ? "bg-transparent"
                      : "bg-slate-300 "
                  } duration-300`}
                >
                  <p> {e.body} </p>{" "}
                  <p className="text-xs text-slate-600 mb-1">
                    {new Date(e.createdAt).toLocaleString()}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Notification;
