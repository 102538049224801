import React from "react";

function EducationalTrackIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 14.728 16.862"
    >
      <g data-name="Group 22357" transform="translate(-358.017 -564.249)">
        <g
          fill="#fff"
          stroke="#177ba3"
          strokeWidth="0.75"
          data-name="Ellipse 188"
          transform="translate(358.017 566.383)"
        >
          <circle cx="7.364" cy="7.364" r="7.364" stroke="none"></circle>
          <circle cx="7.364" cy="7.364" r="6.989" fill="none"></circle>
        </g>
        <g
          fill="#fff"
          stroke="#177ba3"
          strokeWidth="0.75"
          data-name="Ellipse 189"
          transform="translate(360.695 569.061)"
        >
          <circle cx="4.686" cy="4.686" r="4.686" stroke="none"></circle>
          <circle cx="4.686" cy="4.686" r="4.311" fill="none"></circle>
        </g>
        <path
          fill="#fff"
          d="M3.449.366C4.69-1.513 8.037 4.44 6.189 4.684S0 5.678 0 5.678 2.209 2.245 3.449.366z"
          data-name="Path 17875"
          transform="rotate(-33 1140.006 -328.365)"
        ></path>
        <g
          fill="#177ba3"
          stroke="#177ba3"
          strokeWidth="0.75"
          data-name="Ellipse 190"
          transform="translate(363.373 571.739)"
        >
          <circle cx="2.008" cy="2.008" r="2.008" stroke="none"></circle>
          <circle cx="2.008" cy="2.008" r="1.633" fill="none"></circle>
        </g>
        <path
          fill="none"
          stroke="#177ba3"
          strokeWidth="0.75"
          d="M0 6.792L0 0"
          data-name="Line 667"
          transform="translate(365.355 566.837)"
        ></path>
      </g>
    </svg>
  );
}

export default EducationalTrackIcon;
