import React from "react";
import { BsInfoCircle, BsXCircle } from "react-icons/bs";
import { Popup, Progress } from "semantic-ui-react";
import { useAppMedia } from "../../hooks.js/use-media-query";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";

const InstructorsCityListStatus = ({
  totalInstructors,
  cityList,
  states: { setSearchParams, searchParams, limit },
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const { isTablet } = useAppMedia();
  return (
    <div className="mt-8 mb-5 flex flex-col items-center text-[#909090] ltr:ml-20 ltr:mr-8 rtl:mr-20 rtl:ml-8 md:flex-row">
      {/* <span className="flex flex-shrink justify-start whitespace-nowrap">
        {+totalInstructors}{" "}
        {totalInstructors && totalInstructors > 1
          ? selectedContent[localizationKeys.instructorsPlural]
          : selectedContent[localizationKeys.instructor]}
      </span> */}
      <div className="flex max-w-full flex-grow flex-row flex-wrap items-center gap-3 overflow-x-auto ltr:ml-12 rtl:mr-12">
        {cityList?.length > 0 &&
          cityList
            .filter((item) => searchParams.cities?.includes(item._id))
            .map((city) => (
              <div className="flex cursor-default flex-row flex-nowrap items-center gap-4 whitespace-nowrap rounded-full bg-primary-900 px-3 py-1 text-lg text-white outline outline-1 outline-primary-900 transition-[outline] hover:bg-primary-900 hover:bg-opacity-90 ">
                <span className="">{city.name}</span>
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    setSearchParams({
                      subjects: [...searchParams.subjects],
                      cities: searchParams.cities.filter(
                        (item) => item !== city._id
                      ),
                    })
                  }
                >
                  <BsXCircle />
                </span>
              </div>
            ))}
      </div>
    </div>
  );
};

export default InstructorsCityListStatus;
