import React from "react";
import { Field } from "formik";
import { Form, Select } from "semantic-ui-react";

function FormikSelect({
  name,
  className,
  onChangeCallback,
  onBlurCallback,
  options,
  ...props
}) {
  return (
    <>
      <Field name={name}>
        {({ form, field }) => {
          // const { setFieldValue, setFieldTouched } = form;
          const {errors, touched, setFieldValue, setFieldTouched} = form
          return (
            <div>
              <Form.Field
                disabled={options.length === 0}
                control={Select}
                id={name}
                {...field}
                {...props}
                className={`block ${className}`}
                options={options}
                onChange={(e, { value }) => {
                  setFieldValue(name, value);
                  onChangeCallback && onChangeCallback(value);
                }}
                onBlur={() => {
                  setFieldTouched(name, true);
                  onBlurCallback && onBlurCallback(form.values[name]);
                }}
                // error={touched[name] && errors[name]}
                error={Boolean(touched[name] && errors[name])}
              />
            </div>
          );
        }}
      </Field>
    </>
  );
}

export default FormikSelect;
