import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaEye } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { Confirm, Loader } from "semantic-ui-react";
import api from "../../api";
import JobsNotFoundIcon from "../../assets/icons/jobs-notFound-icon";
import PaginationComponent from "../../components/shared/pagination";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import useFilter from "../../lib/use-filter";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { truncateString } from "../../utils/string";
import ViewJobDetails from "./view-job-details";
import { ImBin } from "react-icons/im";
import SearchByPage from "../../components/shared/SearchByPage";

const AllJobsPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { run, isLoading } = useAxios({ data: [] });
  const { run: runDelete, isLoading: isLoadingDelete } = useAxios({ data: [] });

  const [limit] = React.useState("9");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const [isViewingJob, setIsViewingJob] = React.useState(false);
  const [jobFromTable, setJobFromTable] = React.useState(false);
  const [job, setJob] = React.useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);
  const getDataByPage = async () => {
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      run(authAxios.get(`${api.jobs.all}?limit=${limit}&page=${parsed?.page}`))
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, parsed?.page, run]);

  const handleDelete = (id) => {
    runDelete(authAxios.delete(`/vacancies/admin/${id}`))
      .then(() => {
        setIsDeleting(false);
      })
      .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
  };

  return (
    <div>
      {isLoading ? (
        <Loader active={true} />
      ) : data?.data?.length > 0 ? (
        <>
          <div className="relative mb-10 bg-white px-2 py-1 rounded-xl min-h-[33rem] ">
            <div className="w-full grid grid-cols-8 my-4 bg-gray-100 rounded-md text-lightGrey p-2">
              <div className="rtl:mr-10 ltr:ml-10">
                {selectedContent[localizationKeys.jobName]}
              </div>
              <div className="rtl:mr-10 ltr:ml-10 w-full">
                {selectedContent[localizationKeys.school]}
              </div>
              <div className="rtl:mr-16 ltr:ml-16 w-full">
                {selectedContent[localizationKeys.jobLocation]}
              </div>
              <div className="rtl:mr-20 ltr:ml-20 w-full">
                {selectedContent[localizationKeys.workHoursType]}
              </div>
              <div className="rtl:mr-20 ltr:ml-20 w-full">
                {selectedContent[localizationKeys.educationalLevel]}
              </div>
              <div className="rtl:mr-24 ltr:ml-24 w-full">
                {selectedContent[localizationKeys.state]}
              </div>
              <div className="rtl:mr-12 ltr:ml-12">
                {selectedContent[localizationKeys.watch]}
              </div>
              <div className="rtl:mr-12 ">
                {/* {selectedContent[localizationKeys.deleteJob]} */}
                delete job
              </div>
            </div>
            {data?.data?.map((d) => (
              <div
                className="w-full grid grid-cols-8 py-2 items-center"
                key={d._id}
              >
                <div className="rtl:mr-10 ltr:ml-10 w-full">
                  {truncateString(d?.title, 20)}
                </div>
                <div className="rtl:mr-10 ltr:ml-10 w-full">
                  {lang === "ar"
                    ? truncateString(d?.school?.name?.ar, 20)
                    : truncateString(d?.school?.name?.en, 20)}
                </div>
                <div className="rtl:mr-16 ltr:ml-16 w-full">
                  {truncateString(d?.address, 15)}
                </div>
                <div className="rtl:mr-20 ltr:ml-20 w-full">
                  {lang === "ar"
                    ? truncateString(d?.work_type?.name?.ar, 30)
                    : truncateString(d?.work_type?.name?.en, 25)}
                </div>
                <div className="rtl:mr-20 ltr:ml-20">
                  {d?.education_levels?.name}
                </div>
                <div className="rtl:mr-24 ltr:ml-24">
                  {d?.status === "archived" ? (
                    <span className="text-[#D10000]">
                      {selectedContent[localizationKeys.closed]}
                    </span>
                  ) : (
                    <span className="text-[#34C00A]">
                      {selectedContent[localizationKeys.available]}
                    </span>
                  )}
                </div>
                <div className="rtl:mr-16 ltr:ml-16 ">
                  <FaEye
                    className="text-center hover:cursor-pointer"
                    size={16}
                    onClick={() => {
                      setIsViewingJob(true);
                      setJobFromTable(true);
                      setJob(d);
                    }}
                  />
                </div>
                <div>
                  <ImBin
                    className="mx-2 fill-red-600 cursor-pointer"
                    onClick={() => {
                      setJob(d);
                      setIsDeleting(true);
                    }}
                  />
                </div>
              </div>
            ))}
            {/* <div className="flex items-center justify-center">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="absolute bottom-0 my-8 rtl:flex-row-reverse"
              />
            </div> */}
            <div className="flex  gap-5 items-center justify-center mt-5">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className=" rtl:flex-row-reverse"
              />
              <SearchByPage
                getDataByPage={getDataByPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
          <div className="flex-col">
            <JobsNotFoundIcon
              size="80"
              className="justify-center mx-auto text-center my-4"
            />
            <span className="text-xl text-primary text-center">
              {selectedContent[localizationKeys.jobsNotFound]}
            </span>
          </div>
        </div>
      )}
      <ViewJobDetails
        isOpen={isViewingJob}
        onClose={() => {
          setIsViewingJob(false);
          setJob("");
          setJobFromTable(false);
        }}
        job={job}
        jobFromTable={jobFromTable}
      />
      <Confirm
        closeOnEscape
        className="text-center font-extrabold text-black text-xl "
        cancelButton={selectedContent[localizationKeys.cancel]}
        confirmButton={
          isLoadingDelete ? "......" : selectedContent[localizationKeys.delete]
        }
        content={selectedContent[localizationKeys.deleteSubject]}
        size="mini"
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={() => handleDelete(job._id)}
      />
    </div>
  );
};

export default AllJobsPage;
