import React from "react";

function SubjectsIcon({className, size = '1em'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 15.419 19.274"
    >
      <g data-name="Group 22133" transform="translate(-79 -.726)">
        <g data-name="book (1)" transform="translate(75 -1.274)">
          <path
            // fill="#fff"
            d="M16.528 21.274H5.735A1.777 1.777 0 014 19.635V4.891A2.839 2.839 0 016.891 2h10.6a1.933 1.933 0 011.927 1.927v12.528a1.865 1.865 0 01-.964 1.638v1.253a1.933 1.933 0 01-1.926 1.928zm-10.6-1.927h10.6v-.964H7.18a1.177 1.177 0 00-1.253.963zm.963-15.42a.91.91 0 00-.964.964v11.853a2.668 2.668 0 011.253-.289h10.311V3.927z"
            data-name="Path 15406"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M0 0L3 0"
          data-name="Line 665"
          transform="translate(85.5 6.5)"
        ></path>
      </g>
    </svg>
  );
}

export default SubjectsIcon;