import React from "react";
import { Field } from "formik";
import { Form } from "semantic-ui-react";

function FormikInput({ label, name, className, ...props }) {
  return (
    <>
      <Field name={name}>
        {({ form, field }) => {
          const { errors, touched } = form;
          return (
            <div className="w-full">
              <Form.Input
                id={name}
                {...field}
                {...props}
                label={
                  <label
                    htmlFor={name}
                    className="font-bold text-base mt-4 text-gray-600  "
                  >
                    {label}
                  </label>
                }
                className={`block mt-2 w-full ${className}`}
                // error={touched[name] && errors[name]}
                error={Boolean(touched[name] && errors[name])}
              />
              {/* {touched[name] && errors[name] && (
                <ErrorMessage message={errors[name]} />
              )}
              {name === "name.ar" && errors?.name?.ar && touched?.name?.ar && (
                <ErrorMessage message={errors?.name?.ar} />
              )}
              {name === "name.en" && errors?.name?.en && touched?.name?.en && (
                <ErrorMessage message={errors?.name?.en} />
              )} */}
            </div>
          );
        }}
      </Field>
    </>
  );
}

export default FormikInput;
