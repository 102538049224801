import React from "react";

function InstructorNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 69.575 69.575"
    >
      <g
        fill="#c7dfe8"
        stroke="#c7dfe8"
        strokeWidth="0.65"
        data-name="teacher (1)"
        transform="translate(.325 .325)"
      >
        <path
          d="M7.65 0h53.623a7.66 7.66 0 017.652 7.65v35.88a7.661 7.661 0 01-7.652 7.652H36.785v16.453a1.289 1.289 0 01-1.29 1.29h-18.6a1.289 1.289 0 01-1.29-1.29v-7.777H9.922a1.289 1.289 0 01-1.29-1.29v-7.386H7.65A7.661 7.661 0 010 43.53V7.65A7.66 7.66 0 017.65 0zm33.143 48.6c-.038-.036-.079-.069-.116-.105l-3.894-3.894v4zm-29.581 8.678h4.4V42.65a1.29 1.29 0 012.58 0v23.7h16.013V41.488a1.29 1.29 0 012.2-.912l6.1 6.1a3.23 3.23 0 004.574 0l10.1-10.1-3.1-3.109-8.369 8.371a1.29 1.29 0 01-.912.378h-.007a1.29 1.29 0 01-.914-.385l-7.182-7.281a5.965 5.965 0 00-4.226-1.769H19.461a8.256 8.256 0 00-8.249 8.249zM2.58 43.53a5.077 5.077 0 005.07 5.071h.98v-7.57a10.842 10.842 0 0110.831-10.829h13.011a8.559 8.559 0 016.064 2.537l6.261 6.351 7.567-7.567V16.545a1.29 1.29 0 112.58 0v14.148c.014.013.029.021.042.035l4.932 4.932a1.289 1.289 0 010 1.824L48.904 48.496c-.037.037-.078.069-.116.105h12.485a5.077 5.077 0 005.077-5.071V7.65a5.077 5.077 0 00-5.072-5.072H7.65A5.077 5.077 0 002.58 7.65z"
          data-name="Path 18021"
        ></path>
        <path
          d="M25.855 11.867a7.913 7.913 0 11-7.912 7.912 7.913 7.913 0 017.912-7.912zm0 13.245a5.333 5.333 0 10-5.332-5.332 5.333 5.333 0 005.332 5.332z"
          data-name="Path 18022"
        ></path>
      </g>
    </svg>
  );
}

export default InstructorNotFoundIcon;
