import React from "react";

function EducationalLevelIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 15.828 13.314"
    >
      <g
        fill="#177ba3"
        stroke="#177ba3"
        strokeWidth="0.1"
        transform="translate(.049 -14.419)"
      >
        <path
          d="M7.865 22.132a.246.246 0 01-.1-.023L.142 18.524a.246.246 0 010-.445l7.618-3.585a.246.246 0 01.209 0l7.618 3.586a.246.246 0 010 .445l-7.618 3.583a.246.246 0 01-.104.024zM.824 18.3l7.041 3.318 7.041-3.318-7.041-3.312z"
          data-name="Path 16408"
        ></path>
        <path
          d="M2.95 22.523a.246.246 0 01-.246-.246v-2.58a.246.246 0 11.492 0v2.58a.246.246 0 01-.246.246z"
          data-name="Path 16409"
        ></path>
        <path
          d="M12.78 22.523a.246.246 0 01-.246-.246v-2.58a.246.246 0 11.492 0v2.58a.246.246 0 01-.246.246z"
          data-name="Path 16410"
        ></path>
        <path
          d="M7.865 25.045c-2.779 0-5.161-1.522-5.161-2.768a.246.246 0 11.492 0c0 .779 1.966 2.277 4.669 2.277s4.669-1.5 4.669-2.277a.246.246 0 11.492 0c0 1.246-2.382 2.768-5.161 2.768z"
          data-name="Path 16411"
        ></path>
        <path
          d="M11.552 25.1a.246.246 0 01-.246-.246v-4.64l-3.55-1.776a.246.246 0 11.219-.44l3.686 1.843a.246.246 0 01.136.22v4.792a.246.246 0 01-.245.247z"
          data-name="Path 16412"
        ></path>
        <path
          d="M11.551 26.209a.737.737 0 11.737-.737.737.737 0 01-.737.737zm0-.983a.246.246 0 10.246.246.246.246 0 00-.246-.246z"
          data-name="Path 16413"
        ></path>
        <path
          d="M11.92 27.683h-.737a.246.246 0 01-.246-.267l.123-1.475a.5.5 0 00.728-.237.246.246 0 01.255.237l.123 1.475a.246.246 0 01-.246.267zm-.471-.492h.2l-.1-1.208a.1.1 0 010-.018.094.094 0 010 .018z"
          data-name="Path 16414"
        ></path>
      </g>
    </svg>
  );
}

export default EducationalLevelIcon;
