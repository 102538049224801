import React from "react";

function FlagIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 13.815 18.353"
    >
      <g transform="translate(.1 .1)">
        <g data-name="Group 22513">
          <path
            fill="#177ba3"
            stroke="#177ba3"
            strokeWidth="0.2"
            d="M13.312 2.19a.526.526 0 00-.529 0 4.218 4.218 0 01-2.072.533A3.951 3.951 0 017.866 1.6 4.562 4.562 0 004.578.3a4.738 4.738 0 00-2.762.859V.908a.908.908 0 00-1.814 0v16.337a.908.908 0 001.815 0V9.379a.529.529 0 00.275-.109 4.1 4.1 0 012.487-.8 3.957 3.957 0 012.85 1.119 4.56 4.56 0 003.283 1.3 4.729 4.729 0 002.644-.772.605.605 0 00.26-.514v-6.88a.605.605 0 00-.304-.533zm-12.1 15.055a.302.302 0 11-.605 0V.908a.302.302 0 01.605 0zm11.8-7.627a4.184 4.184 0 01-2.3.669 3.951 3.951 0 01-2.844-1.119 4.562 4.562 0 00-3.289-1.3 4.735 4.735 0 00-2.762.853V1.942A4.006 4.006 0 014.578.908a3.957 3.957 0 012.85 1.119 4.56 4.56 0 003.283 1.3 4.841 4.841 0 002.3-.563v6.854z"
            data-name="Path 18024"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default FlagIcon;
