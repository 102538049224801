import React from "react";
import { useLanguage } from "../../lib/language-context";

function ChangePassword({ isChange }) {
  const [lang, setLang] = useLanguage();

  return (
    <>
      <section className="bg-gray-50 ">
        <div
          className={`flex flex-col items-center justify-center px-6 py-8 mx-auto h-[70vh]  lg:py-0`}
        >
          <div className="w-full p-6 shadow-xl rounded-lg  dark:border md:mt-0 sm:max-w-md dark:border-gray-700 sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-dark">
              {lang === "en" ? "reset password" : "تغير كلمة السر"}
            </h2>
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
              {isChange && (
                <div>
                  <label
                    htmlFor="oldPassword"
                    className="block mb-2 text-lg font-medium text-gray-900 dark:text-dark"
                  >
                    {lang === "en" ? "old password" : " كلمة السر القديمه"}
                  </label>
                  <input
                    type="password"
                    name="oldPassword"
                    id="oldPasswrod"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              )}
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-lg font-medium text-gray-900 dark:text-dark"
                >
                  {lang === "en" ? "new password" : "كلمة السر الجديده"}
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 sm:text-lg font-medium text-gray-900 dark:text-dark"
                >
                  {lang === "en"
                    ? "confirm new password"
                    : "تاكيد كلمة السر الجديده"}
                </label>
                <input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>

              <button
                type="submit"
                className=" text-dark bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {lang === "en" ? "reset password" : "تغير كلمة السر"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
