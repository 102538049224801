import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import routes from "../routes";
import AllSubjectsPage from "../pages/subjects/all-subjects-page";
import AllEducationalOfficesPage from "../pages/educational-offices/all-educational-offices-page";
import useMediaQuery from "../hooks.js/use-media-query";
import AllSchoolsPage from "../pages/Schools/all-schools-page";

import SchoolProfilePage from "../pages/Schools/school-profile-page";
import SidebarComponent from "../components/shared/sidebar";
import NavbarComponent from "../components/shared/navbar";
import AllJobsPage from "../pages/jobs/all-jobs-page";
import SettingsPage from "../pages/settings/settings-page";
import AllInstructorsPage from "../pages/instructors/all-instructors-page";
import InstructorProfilePage from "../pages/instructors/instructor-profile-page";
import ForgetPasswordLayout from "../pages/forget-password-redirect/forget-password-layout";
import ViewAllImages from "../pages/Schools/components/ViewAllImages";

const SidebarLayout = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const isSmall = useMediaQuery("(max-width: 1100px)");

  return (
    <div>
      <NavbarComponent
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isSmall={isSmall}
      />
      <div className="flex">
        <SidebarComponent
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          isSmall={isSmall}
        />
        <div
          className={`${
            isSmall ? "" : "lg:ltr:ml-80 lg:rtl:mr-80"
          } lg:ltr:mr-8 lg:rtl:ml-8 mt-28 w-full mx-auto px-8 bg-none`}
        >
          <Switch>
            <Route
              exact
              path={routes.educationalOffices.all}
              component={AllEducationalOfficesPage}
            />
            <Route path={routes.schools.all} component={AllSchoolsPage} />
            <Route
              path={routes.schools.profile()}
              component={SchoolProfilePage}
            />
            <Route path={routes.subjects.all} component={AllSubjectsPage} />
            <Route path={routes.jobs.all} component={AllJobsPage} />
            <Route path={routes.settings} component={SettingsPage} />
            <Route
              path={routes.instructors.all}
              component={AllInstructorsPage}
            />
            <Route path={routes.viewAllImages(":id")} component={ViewAllImages} />
            <Route
              path={routes.instructors.profile()}
              component={InstructorProfilePage}
            />
            <Redirect to={routes.educationalOffices.all} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
