const api = {
  auth: {
    login: "/admins/login",
    refreshToken: "/admins/refreshToken",
  },

  forgotPassword: {
    all: "/admins/forgot-password",
  },
  notification: {
    all: "/admins/notifications",
  },
  educationalOffices: {
    all: "/education-centers",
    notPaginated: "/education-centers/all",
  },
  schools: {
    all: "/schools",
    details: (schoolId = ":schoolId") => `schools/${schoolId}/details`,
    schoolTypes: (type) => `/schools?school_type=${type}`,
    excel: "/schools/export/excel",
  },
  album: {
    viewAllAlbum: (schoolId = ":schoolId") => `schools/${schoolId}/all-albums`,
    toggleImageVisibility: (id) => `/schools/${id}/visibility`,
  },
  instructor: {
    all: "/instructors",
    profile: (id = ":id") => `/instructors/${id}`,
  },
  city: { all: "/cities" },
  subjects: { all: "/subjects" },
  educationalLevels: { all: "/education-levels" },
  tracks: { all: "/secondary-tracks" },
  jobs: { all: "/vacancies" },
  specializations: {
    instructor: (instructorId = ":instructorId") =>
      `/specializations?instructorId=${instructorId}`,
  },
  trainingCourses: {
    instructor: (instructorId = ":instructorId") =>
      `/training-courses?instructorId=${instructorId}`,
  },
  workExperience: {
    instructor: (instructorId = ":instructorId") =>
      `/work-experience?instructorId=${instructorId}`,
  },
  sendPaymentPrices: "payment-prices",
};

export default api;
