import React from "react";
import { Icon, Pagination } from "semantic-ui-react";
import SearchByPage from "./SearchByPage";

const PaginationComponent = ({ totalPages, page, setPage, className }) => {
  return (
    <>
      <Pagination
        className={`${className} `}
        size="mini"
        boundaryRange={1}
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" />,
          icon: true,
        }}
        siblingRange={1}
        totalPages={totalPages || 1}
        activePage={page}
        firstItem={{
          content: <Icon name="angle double left" />,
          icon: true,
        }}
        lastItem={{
          content: <Icon name="angle double right" />,
          icon: true,
        }}
        prevItem={{
          content: <Icon name="angle left" />,
        }}
        nextItem={{ content: <Icon name="angle right" /> }}
        onPageChange={(_, data) => setPage(data.activePage)}
      />
      <div>
       
      </div>
    </>
  );
};

export default PaginationComponent;
