import classNames from "classnames";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Search } from "semantic-ui-react";
import { authAxios } from "../../../config/axios-config";
import { useAppMedia } from "../../../hooks.js/use-media-query";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";

import styles from "./city-filter-modal.module.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CityFilterModal = ({
  states: { cityFilterModal, setCityFilterModal, cityList, setCityList },
  setSearchParams,
  searchParams,
}) => {
  const [lang] = useLanguage();
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const selectedContent = content[lang];
  const { isTablet } = useAppMedia();
  const cityFilterModalAction = {
    open: () =>
      setCityFilterModal({
        ...CityFilterModal,
        open: true,
      }),
    close: () => {
      setCityFilterModal({ open: false });
    },
    cancel: () => {
      setSearchParams(
        (prev) =>
          CityFilterModal.initSelect || {
            subjects: [...prev.subjects],
            cities: [],
          }
      );
      setCityFilterModal({ open: false });
    },
    confirm: () => {
      if (searchParams.cities?.length < 1) {
        history.replace({ search: `` });
      } else if (location.search?.includes("subjects")) {
        const subjectUrl = location.search.split("subjects")[1];
        history.replace({
          search: `?subjects${subjectUrl}&cities${
            searchParams.cities?.length?.length === 1 ? "[]" : ""
          }=${searchParams.cities.join(",")}`,
        });
      } else {
        history.replace({
          search: `?cities${
            searchParams.cities?.length?.length === 1 ? "[]" : ""
          }=${searchParams.cities.join(",")}`,
        });
      }
      cityFilterModalAction.close();
    },
  };

  const addCityToUrl = (city, e) => {
    e.stopPropagation();

    if (!searchParams.cities?.includes(city._id) && e.target.checked) {
      searchParams.cities.push(city._id);
    } else if (searchParams.cities?.includes(city._id) && !e.target.checked) {
      setSearchParams({
        subjects: [...searchParams.subjects],
        cities: searchParams.cities.filter((sub) => sub !== city._id),
      });
    }
  };

  useEffect(() => {
    // console.log("city list===> ", cityList);
    authAxios
      .get("/cities", { params: { name: search || null } })
      .then((res) => setCityList(res.data.data));
  }, [search]);

  useEffect(() => {
    authAxios.get("/cities").then((res) => setCityList(res.data.data));
  }, []);

  const FilterSearch = () => (
    <Search
      autoFocus="autoFocus"
      input={{
        icon: "search",
        iconPosition: "left",
      }}
      open={false}
      className={classNames(styles.field, {
        "my-3 w-full": isTablet,
      })}
      value={search}
      onSearchChange={(e, data) => setSearch(data?.value)}
    />
  );
  return (
    <Modal
      onClose={cityFilterModalAction.close}
      onOpen={cityFilterModalAction.open}
      open={cityFilterModal.open}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      className="relative min-h-[10rem] max-w-xl"
    >
      <Modal.Content className="mb-0 pb-0">
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full flex-row items-center justify-between gap-1">
            <p className="whitespace-nowrap">
              {selectedContent[localizationKeys.searchByCity]}
            </p>
            {!isTablet && <FilterSearch />}
            <Button
              className={classNames("bg-transparent p-0", {
                "mr-auto": lang === "ar",
                "ml-auto": lang !== "ar",
              })}
              onClick={() =>
                setSearchParams({
                  subjects: [...searchParams.subjects],
                  cities: [],
                })
              }
            >
              {selectedContent[localizationKeys.reset]}
            </Button>
          </div>
          {isTablet && <FilterSearch />}
          <div className="mt-5 flex flex-row flex-wrap gap-4">
            {cityList?.length > 0 &&
              cityList.map((city, index) => (
                <label className="hover:cursor-pointer" key={index}>
                  <input
                    type="checkbox"
                    tabIndex="0"
                    className="peer hidden"
                    defaultChecked={searchParams.cities?.includes(city._id)}
                    onClick={(e) => {
                      addCityToUrl(city, e);
                      // e.stopPropagation();
                      // if (selected?.includes(city._id) && !e.target.checked) {
                      //   setSelected(
                      //     [...selected].filter((sub) => sub !== city._id)
                      //   );
                      // } else if (e.target.checked) {
                      //   setSelected([...selected, city._id]);
                      // }
                    }}
                  />
                  <div className="text-primary rounded-full px-4 py-1 text-lg outline outline-1 outline-primary-900 transition-[outline] hover:bg-primary-900 hover:bg-opacity-90 hover:text-white peer-checked:bg-primary-900 peer-checked:text-white">
                    {city.name}
                  </div>
                </label>
              ))}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className="my-4 flex flex-col justify-end gap-1 rounded-lg md:flex-row">
        <Button
          content={selectedContent[localizationKeys.cancel]}
          basic
          color="blue"
          className="w-full min-w-min md:w-1/4"
          onClick={cityFilterModalAction.cancel}
        />
        <Button
          content={selectedContent[localizationKeys.filter]}
          color="blue"
          className="w-full min-w-min md:w-1/4"
          onClick={cityFilterModalAction.confirm}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CityFilterModal;
