import React from "react";

function ImageEmptyState({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 29.352 25.683"
    >
      <path
        // fill="#a7a7a7"
        d="M30.085 5.691V27.7H4.408V5.694zm0-1.834H4.405a1.84 1.84 0 00-1.834 1.834V27.7a1.84 1.84 0 001.835 1.835h25.682a1.84 1.84 0 001.835-1.835V5.691a1.84 1.84 0 00-1.835-1.835zm-3.669 6.421a2.752 2.752 0 11-2.752-2.752 2.752 2.752 0 012.752 2.752zm1.838 15.592H6.24V22.2l6.42-11.006L20 20.367h1.834l6.421-5.5z"
        data-name="Icon metro-image"
        transform="translate(-2.571 -3.856)"
      ></path>
    </svg>
  );
}

export default ImageEmptyState;
