import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import InstructorNotFoundIcon from "../../assets/icons/instructors-not-found-icon";
import InstructorRow from "../../components/instructors/instructor-row";
import InstructorsListFilters from "../../components/instructors/instructors-list-filters";
import InstructorsListStatus from "../../components/instructors/instructors-list-status";
import SubjectFilterModal from "../../components/instructors/subject-filter-modal/subject-filter-modal";
import PaginationComponent from "../../components/shared/pagination";
import { useLanguage } from "../../lib/language-context";
import useFilter from "../../lib/use-filter";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { instructorAPI } from "../../services/instructor-list";
import {
  actionReducers,
  initialState,
  reducer,
} from "./instructors-list-reducer";
import InstructorsListCityFilters from "../../components/instructors/instructors-list-city-filters";
import CityFilterModal from "../../components/instructors/city-filter-modal/city-filter-modal";
import InstructorsCityListStatus from "../../components/instructors/instructors-city-list-status";
import { authAxios } from "../../config/axios-config";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import SearchByPage from "../../components/shared/SearchByPage";

const AllInstructorsPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [
    {
      loading,
      viewLimit,
      selected,
      subjectList,
      cityList,
      instructors,
      totalPages,
      totalInstructors,
      modal,
      filterModal,
      cityFilterModal,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const {
    setLoading,
    setInstructors,
    setModal,
    setSubjectList,
    setCityList,
    setSelected,
    setFilterModal,
    setCityFilterModal,
    setTotalPages,
    setTotalInstructors,
    setViewLimit,
  } = actionReducers(dispatch);

  const [searchParams, setSearchParams] = useState({
    subjects: [],
    cities: [],
  });

  const queryString = require("query-string");
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const parsed = queryString.parse(location.search, { arrayFormat: "comma" });
  const [pageNumber, setPageNumber] = useState(1);

  const [page, setPage] = useFilter("page", parsed.page || 1);

  const limit = 10;

  const ModalAction = {
    open: (instructor) =>
      setModal({
        ...modal,
        instructor: { ...instructor },
        open: true,
      }),
    close: () => setModal({ open: false }),
    confirm: async () => {
      setModal({ ...modal, loading: "loading" });
      instructorAPI
        .payToView(modal.instructor._id)
        .then((res) => res.data)
        .then((data) => {
          window.open(data.InvoiceURL, "_blank");
        })
        .finally((data) => ModalAction.close());
    },
  };
  const getDataByPage = async () => {
    setPage(pageNumber);
  };

  const fetchInstructorList = async (params) => {
    setLoading("loading");

    instructorAPI
      .listInstructors(params)
      .then((data) => {
        // `console.log`(data.data.map((sub) => sub.city.name));
        // const city = data.data.filter((datas) => console.log(datas.city.name === 'الاسكندريه'));
        // setByCity(city);
        setInstructors(data.data);
        setTotalPages(data.totalPages);
        setViewLimit(data.instructorViewsLimit);
        setTotalInstructors(data.totalLength);
        setLoading("done");
      })
      .finally(() => {
        setLoading("done");
      });
  };

  const DownloadExellFile = async () => {
    setIsLoading(true);
    await authAxios
      .get(
        `https://ejad-backend-dev.onrender.com/api/v1/instructors/exportexcel?${searchParams.cities
          .map((city) => "cities[]=" + city)
          .join("&")}&${searchParams.subjects
          .map((sub) => "subjects[]=" + sub)
          .join("&")}`,
        { responseType: "blob" }
      )
      .then((response) => {
        setIsLoading(false);

        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search, { arrayFormat: "comma" });
    const params = {
      ...parsed,
      limit,
    };
    fetchInstructorList(params);
    console.log(params);
  }, [location.search]);

  useEffect(() => {
    if (filterModal.open || cityFilterModal.open) return;
    if (searchParams.subjects?.length < 1 && searchParams.cities?.length < 1) {
      history.replace({ search: `` });
    } else {
      if (
        history.location.search.includes("subjects") &&
        !history.location.search.includes("cities")
      ) {
        history.replace({
          search: `?subjects${
            searchParams.subjects?.length === 1 ? "[]" : ""
          }=${searchParams.subjects?.join(",")}`,
        });
      } else if (!history.location.search.includes("subjects")) {
        history.replace({
          search: `?cities${
            searchParams.cities?.length === 1 ? "[]" : ""
          }=${searchParams.cities?.join(",")}`,
        });
      } else {
        history.replace({
          search: `?cities${
            searchParams.cities?.length === 1 ? "[]" : ""
          }=${searchParams.cities?.join(",")}&subjects${
            searchParams.subjects?.length === 1 ? "[]" : ""
          }=${searchParams.subjects?.join(",")}`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const InstructorsList = ({ instructors }) => (
    <div className="relative mb-10 bg-white px-2 py-1 rounded-xl min-h-[36rem] ">
      <div className="table-header mb-[16px] grid-cols-7 hover:shadow-none">
        <div>{selectedContent[localizationKeys.instructorName]}</div>
        <div className="mx-auto">
          {selectedContent[localizationKeys.eduLevel]}
        </div>
        <div className="mx-auto">
          {selectedContent[localizationKeys.subject]}
        </div>
        <div className="mx-auto">
          {selectedContent[localizationKeys.nationality]}
        </div>
        <div className="col-span-2 mx-auto">
          {selectedContent[localizationKeys.cv]}
        </div>
        <div className="mx-auto">{selectedContent[localizationKeys.view]}</div>
      </div>
      {instructors?.map((instructor) => (
        <InstructorRow
          key={instructor._id}
          instructor={instructor}
          modalOpen={() => ModalAction.open(instructor)}
        />
      ))}

      <div className="flex  gap-5 items-center justify-around mt-8">
        <div className="exel-sheet">
          <button
            disabled={isLoading}
            className="bg-[#177BA3] text-white px-8 py-3 rounded-md "
            onClick={DownloadExellFile}
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              selectedContent[localizationKeys.ExportFile]
            )}
          </button>
        </div>
        <div className="flex justify-center items-center gap-4">
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            className=" rtl:flex-row-reverse"
          />
          <SearchByPage
            getDataByPage={getDataByPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
      <div className=""></div>
    </div>
  );

  return (
    <div className="px-8">
      <InstructorsListFilters
        filterState={{
          selected,
          filterModal,
          setFilterModal,
        }}
      />
      <InstructorsListCityFilters
        filterState={{
          selected,
          cityFilterModal,
          setCityFilterModal,
        }}
      />
      {(searchParams.subjects?.length > 1 ||
        searchParams.cities?.length > 1) && (
        <div className="w-full flex rtl:justify-end ltr:justify-start  ">
          <div
            onClick={() => {
              setSearchParams({
                cities: [],
                subjects: [],
              });
            }}
            className="flex w-fit cursor-default  ltr:ml-auto rtl:mr-auto  flex-nowrap items-center gap-4 whitespace-nowrap rounded-xl bg-red-700 px-10 py-2 text-lg text-white outline outline-1 outline-red-900 transition-[outline] hover:bg-red-600 hover:bg-opacity-90 "
          >
            <span className="">
              {" "}
              {selectedContent[localizationKeys.ClearFilter]}
            </span>
          </div>
        </div>
      )}
      <InstructorsListStatus
        totalInstructors={totalInstructors}
        subjectsList={subjectList}
        states={{ searchParams, setSearchParams, limit: viewLimit }}
      />
      <InstructorsCityListStatus
        totalInstructors={totalInstructors}
        cityList={cityList}
        states={{ searchParams, setSearchParams, limit: viewLimit }}
      />

      {loading === "loading" ? (
        <Loader active={true} />
      ) : instructors?.length > 0 ? (
        <InstructorsList instructors={instructors} />
      ) : (
        <div className="bg-white px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
          <div className="flex-col">
            <InstructorNotFoundIcon
              size="80"
              className="justify-center mx-auto text-center my-4"
            />
            <span className="text-xl text-primary text-center">
              {selectedContent[localizationKeys.instructorsNotFound]}
            </span>
          </div>
        </div>
      )}
      <SubjectFilterModal
        states={{
          filterModal,
          setFilterModal,
          selected,
          setSelected,
          subjectList,
          setSubjectList,
        }}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <CityFilterModal
        states={{
          cityFilterModal,
          setCityFilterModal,
          selected,
          setSelected,
          cityList,
          setCityList,
        }}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
    </div>
  );
};

export default AllInstructorsPage;
