import React from "react";

function InstructorIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 17.748 17.748"
    >
      <g
        // fill="#177ba3"
        // stroke="#177ba3"
        strokeWidth="0.65"
        data-name="teacher (1)"
        transform="translate(.325 .325)"
      >
        <path
          d="M1.898 0h13.3a1.9 1.9 0 011.9 1.9v8.9a1.9 1.9 0 01-1.9 1.9H9.125v4.081a.32.32 0 01-.32.32H4.191a.32.32 0 01-.32-.32V14.85h-1.41a.32.32 0 01-.32-.32v-1.834h-.243a1.9 1.9 0 01-1.9-1.9v-8.9A1.9 1.9 0 011.898 0zm8.222 12.056l-.029-.026-.966-.966v.992zm-7.338 2.152h1.09v-3.63a.32.32 0 11.64 0v5.879h3.973v-6.165a.32.32 0 01.546-.226l1.512 1.512a.8.8 0 001.135 0l2.505-2.505-.77-.771-2.076 2.076a.32.32 0 01-.226.094h0a.32.32 0 01-.227-.1L9.105 8.567a1.48 1.48 0 00-1.048-.439H4.828a2.048 2.048 0 00-2.046 2.046zm-2.141-3.41a1.259 1.259 0 001.258 1.258h.243v-1.878a2.689 2.689 0 012.686-2.686h3.228a2.123 2.123 0 011.5.629l1.553 1.575 1.877-1.877V4.104a.32.32 0 01.64 0v3.509l.01.009 1.227 1.228a.32.32 0 010 .453l-2.732 2.727-.029.026h3.1a1.259 1.259 0 001.258-1.258v-8.9A1.259 1.259 0 0015.2.64H1.9A1.259 1.259 0 00.642 1.898z"
          data-name="Path 18021"
        ></path>
        <path
          d="M6.409 2.944a1.963 1.963 0 11-1.963 1.963 1.963 1.963 0 011.963-1.963zm0 3.285a1.323 1.323 0 10-1.323-1.323 1.323 1.323 0 001.323 1.323z"
          data-name="Path 18022"
        ></path>
      </g>
    </svg>
  );
}

export default InstructorIcon;
