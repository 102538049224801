import React from "react";

function JobsIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 19.279 17.39"
    >
      <path
        // fill="#fff"
        stroke="#fff"
        strokeWidth="0.39"
        d="M18.139 3.028h-4.092V1.769A1.576 1.576 0 0012.473.195H6.806a1.576 1.576 0 00-1.574 1.574v1.259H1.139a.944.944 0 00-.944.945v7.008a.949.949 0 00.63.889v4.381a.944.944 0 00.944.944H17.51a.944.944 0 00.944-.944V11.87a.949.949 0 00.63-.889V3.973a.944.944 0 00-.944-.944zM5.862 1.769a.944.944 0 01.944-.944h5.667a.944.944 0 01.944.944v1.259h-.63V1.769a.315.315 0 00-.315-.315H6.806a.315.315 0 00-.315.315v1.259h-.63zm6.3 1.259H7.121v-.944h5.037zm5.663 13.223a.315.315 0 01-.315.315H1.769a.315.315 0 01-.315-.315v-4.274l6.926.955v.8a.944.944 0 00.944.944h.63a.944.944 0 00.944-.944v-.8l6.926-.955zm-7.556-2.519a.315.315 0 01-.315.315h-.629a.315.315 0 01-.315-.315v-1.889a.315.315 0 01.315-.315h.63a.315.315 0 01.315.315zm8.185-2.752a.315.315 0 01-.271.312l-.087.012-7.2.993v-.454a.944.944 0 00-.944-.944h-.627a.944.944 0 00-.944.944v.452l-7.286-1a.315.315 0 01-.271-.312v-7.01a.315.315 0 01.315-.315h17a.315.315 0 01.315.315zm0 0"
      ></path>
    </svg>
  );
}

export default JobsIcon;
