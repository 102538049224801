import React from "react";

function JobsNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
    width={size}
    height={size}
    className={className}
      viewBox="0 0 129.39 116.49"
    >
      <path
        fill="rgba(23,123,163,0.23)"
        stroke="#fff"
        strokeWidth="0.39"
        d="M122.745 19.545h-27.95v-8.6A10.763 10.763 0 0084.045.195h-38.7a10.763 10.763 0 00-10.75 10.75v8.6H6.645a6.45 6.45 0 00-6.45 6.45v47.858a6.478 6.478 0 004.3 6.07v29.922a6.45 6.45 0 006.45 6.45h107.5a6.45 6.45 0 006.45-6.45V79.926a6.48 6.48 0 004.3-6.072V25.995a6.45 6.45 0 00-6.45-6.45zm-83.85-8.6a6.45 6.45 0 016.45-6.45h38.7a6.45 6.45 0 016.45 6.45v8.6h-4.3v-8.6a2.15 2.15 0 00-2.15-2.15h-38.7a2.15 2.15 0 00-2.15 2.15v8.6h-4.3zm43 8.6h-34.4v-6.45h34.4zm38.7 90.3a2.15 2.15 0 01-2.15 2.15h-107.5a2.15 2.15 0 01-2.15-2.15V80.663l47.3 6.523v5.459a6.45 6.45 0 006.45 6.45h4.3a6.45 6.45 0 006.45-6.45v-5.459l47.3-6.523zm-51.6-17.2a2.15 2.15 0 01-2.15 2.15h-4.3a2.15 2.15 0 01-2.15-2.15v-12.9a2.15 2.15 0 012.15-2.15h4.3a2.15 2.15 0 012.15 2.15zm55.9-18.792a2.15 2.15 0 01-1.849 2.131l-.6.082-49.151 6.779v-3.1a6.45 6.45 0 00-6.45-6.45h-4.3a6.45 6.45 0 00-6.45 6.45v3.1l-49.749-6.86a2.15 2.15 0 01-1.851-2.131V25.995a2.15 2.15 0 012.15-2.15h116.1a2.15 2.15 0 012.15 2.15zm0 0"
      ></path>
    </svg>
  );
}

export default JobsNotFoundIcon;
