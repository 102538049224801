import React from "react";

function useMediaQuery(query, initialState = false) {
  const [state, setState] = React.useState(initialState);
  React.useDebugValue(
    { query, initialState },
    ({ query, initialState }) => `\`${query}\` => ${initialState}`
  );

  React.useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    function onChange() {
      if (!mounted) {
        return;
      }
      setState(Boolean(mql.matches));
    }

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
}

export default useMediaQuery;

export const useAppMedia = () => {
  const isSmallMobile = useMediaQuery("(max-width: 768px)"); //
  const isMobile = useMediaQuery("(max-width: 776px)"); //mob
  const isTablet = useMediaQuery("(max-width: 860px)");
  const isLaptop = useMediaQuery("(max-width: 992px)");
  const isScreen = useMediaQuery("(max-width: 1100px)");
  const isLargeScreen = useMediaQuery("(min-width:1750px)");

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isLaptop,
    isScreen,
    isLargeScreen,
  };
};
