import { authAxios } from "../config/axios-config";

export const instructorAPI = {
  listInstructors: async (params) =>
    authAxios.get(`/instructors`, { params }).then((res) => res.data),

  payToView: async (instructorId) =>
    authAxios
      .get(`/schools/instructors/${instructorId}/pay-to-view`)
      .then((res) => res.data),
};
