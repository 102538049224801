import React from "react";

function SubjectsNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 56.836 71.045"
    >
      <g
        data-name="Group 22284"
        opacity="0.23"
        transform="translate(-79 -.726)"
      >
        <g data-name="book (1)" transform="translate(79 .726)">
          <path
            fill="#177ba3"
            d="M50.179 73.045H10.394A6.552 6.552 0 014 67.006V12.657A10.466 10.466 0 0114.657 2h39.074a7.125 7.125 0 017.1 7.1v46.183a6.876 6.876 0 01-3.552 6.039v4.618a7.125 7.125 0 01-7.1 7.105zM11.1 65.94h39.079v-3.552H15.722A4.339 4.339 0 0011.1 65.94zM14.657 9.1a3.356 3.356 0 00-3.557 3.557v43.692a9.833 9.833 0 014.618-1.066h38.013V9.1z"
            data-name="Path 15406"
            transform="translate(-4 -2)"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#177ba3"
          strokeLinecap="round"
          strokeWidth="4.5"
          d="M0 0L11.058 0"
          data-name="Line 665"
          transform="translate(102.96 22.008)"
        ></path>
      </g>
    </svg>
  );
}

export default SubjectsNotFoundIcon;
