import React from "react";

function SchoolsNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 61.821 68.69"
    >
      <path
        fill="rgba(23,123,163,0.23)"
        fillRule="evenodd"
        d="M61.386 70.69a3.436 3.436 0 003.434-3.434V5.434A3.436 3.436 0 0061.386 2H27.041a3.436 3.436 0 00-3.434 3.434v24.042H6.434A3.436 3.436 0 003 32.91v34.345a3.436 3.436 0 003.434 3.435zm-20.607-6.869h-10.3V8.869h27.473v54.952h-10.3v-6.869a3.434 3.434 0 10-6.869 0zM23.607 36.345v27.476H9.869V36.345zm24.041 0h-6.869a3.434 3.434 0 110-6.869h6.869a3.434 3.434 0 110 6.869zm0-13.738h-6.869a3.434 3.434 0 110-6.869h6.869a3.434 3.434 0 010 6.869z"
        transform="translate(-3 -2)"
      ></path>
    </svg>
  );
}

export default SchoolsNotFoundIcon;
