import React from "react";

function ProfileIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      data-name="Group 11"
      viewBox="0 0 40 40"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        fill="#177BA3"
        data-name="Ellipse 6"
      ></circle>
      <path
        fill="#fcfcfc"
        fillRule="evenodd"
        d="M5 20.3a3.762 3.762 0 013.814-3.7h10.17a3.762 3.762 0 013.816 3.7v3.708a1.254 1.254 0 01-1.271 1.236H6.271A1.254 1.254 0 015 24.011zm2.543 2.472V20.3a1.254 1.254 0 011.271-1.236h10.17a1.254 1.254 0 011.271 1.236v2.472zM8.814 6.708A3.762 3.762 0 0112.628 3h2.542a3.762 3.762 0 013.814 3.708v3.708a3.762 3.762 0 01-3.814 3.708h-2.542a3.762 3.762 0 01-3.814-3.708zm2.543 0a1.254 1.254 0 011.271-1.236h2.542a1.254 1.254 0 011.271 1.236v3.708a1.254 1.254 0 01-1.271 1.236h-2.542a1.254 1.254 0 01-1.271-1.236z"
        transform="translate(6.101 5.876)"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
