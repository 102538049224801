import React, { useState } from "react";
import { FaSlidersH } from "react-icons/fa";
import ProfileIcon from "../../assets/icons/profile-icon";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import Notification from "../notification/Notification";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import toast from "react-hot-toast";
import { useEffect } from "react";

const NavbarComponent = ({ isVisible, setIsVisible, isSmall }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  // const [notifications, setNotifications] = useState([
  //   {
  //     name: "awd",
  //     time: "12m ago",
  //   },
  //   {
  //     name: "nour",
  //     time: "1h ago",
  //   },
  //   {
  //     name: "ali",
  //     time: "2m ago",
  //   },
  //   {
  //     name: "ahmed",
  //     time: "12s ago",
  //   },
  // ]);
  const [notifications, setNotifications] = useState([{}]);

  useEffect(() => {
    getNotifications();

  }, []);

  // setInterval(() => {

  //   getNotifications()
  // // }, 8000);

  const getNotifications = async () => {
    await authAxios
      .get(api.notification.all)
      .then(({ data }) => {
        if (data.status === true) return setNotifications(data.data);
      })
      .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
  };

  return (
    <nav
      // className="fixed w-full bg-white px-8 py-2 flex justify-end items-center shadow-sm h-20"
      className={`${
        isSmall ? "justify-between" : "justify-end "
      } fixed w-full bg-white px-8 py-2 flex items-center shadow-sm h-20 z-20 gap-x-2`}
    >
      <div className="flex items-center">
        {isSmall && (
          <FaSlidersH
            size={30}
            className="fill-[#FCFCFC] bg-primary rounded-lg p-2 mx-2 hover:cursor-pointer"
            onClick={() => setIsVisible(!isVisible)}
          />
        )}
      </div>
      <div className=" relative flex items-center justify-center w-[2.3rem] h-[2.3rem] bg-[#177ba3] rounded-full">
        <Notification notifications={notifications} />
      </div>

      <div className="flex items-center">
        <div className="flex items-center">
          <ProfileIcon size="30" />
          <div className="flex-col px-4">
            <div className="text-gray-700 ml-2 ">admin@admin.com</div>
            <div className="text-xs -mb-1">
              {selectedContent[localizationKeys.admin]}
            </div>
          </div>
        </div>
        {/* {lang === "ar" && (
          <Button
            circular
            onClick={() => setLang("en")}
            className="font-sans flex items-center justify-center text-sm bg-white h-10
          w-4 ring-1 ring-primary rtl:mr-6 ltr:ml-6 text-primary "
          >
            EN
          </Button>
        )}
        {lang === "en" && (
          <Button
            circular
            onClick={() => setLang("ar")}
            className="flex items-center justify-center text-sm  bg-white h-10 w-4 ring-1
          ring-primary rtl:mr-6 ltr:ml-6 text-primary "
          >
            AR
          </Button>
        )} */}
      </div>
    </nav>
  );
};

export default NavbarComponent;
