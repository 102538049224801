const routes = {
  login: "/auth/login",
  settings: "/settings",
  subjects: {
    all: "/subjects",
  },
  educationalOffices: {
    all: "/educationalOffices",
  },
  schools: {
    all: "/schools",
    profile: (id = ":id") => `/schoolProfile/${id}`,
  },
  instructors: {
    all: "/instructors",
    profile: (id = ":id") => `/instructorProfile/${id}`,
    profilePage: "/app/instructor-page/:instructorId",
  },
  jobs: {
    all: "/jobs",
  },
  resetPassword: {
    all: "/reset",
  },
  viewAllImages:(id)=> `/view-all-images/${id}`, 
};
export default routes;
