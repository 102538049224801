import { parse, stringify } from "query-string";
import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import SidebarLayout from "./layouts/sidebar-layout";
import { useLanguage } from "./lib/language-context";
import LoginPage from "./pages/auth/login-page";
import routes from "./routes";
import ForgetPasswordLayout from "./pages/forget-password-redirect/forget-password-layout";

function App() {
  const [lang] = useLanguage();

  React.useEffect(() => {
    const directionTag = document
      .getElementsByTagName("html")[0]
      .getAttribute("dir");
    const style = document.getElementById("style");

    if (directionTag === "ltr") {
      style.href = "/assets/css/semantic.min.css";
      document.title = "Ejad - admin";
    } else {
      style.href = "/assets/css/semantic.rtl.min.css";
      document.title = "منصة إجاد - أدمن";
    }
  }, [lang]);
  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <ErrorBoundary
          fallback={() => (
            <div className="w-screen h-screen flex justify-center items-center text-2xl font-bold">
              Something went wrong ...
            </div>
          )}
        >
          <Switch>
            <Route exact path={routes.login} component={LoginPage} />
            <Route
              path={routes.resetPassword.all}
              component={ForgetPasswordLayout}
            />
            <Route path="/" component={SidebarLayout} />
            <Redirect to={routes.login} />
          </Switch>
        </ErrorBoundary>
        <Toaster />
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
