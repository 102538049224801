import React from "react";

function EducationalOfficesNotFoundIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 73.125 65"
    >
      <path
        fill="rgba(23,123,163,0.23)"
        fillRule="evenodd"
        d="M39.563 69h-32.5a4.063 4.063 0 010-8.125h32.5a4.063 4.063 0 010 8.125zM51.75 48.688a4.064 4.064 0 014.063-4.062h16.25a4.064 4.064 0 014.063 4.063v16.25A4.064 4.064 0 0172.063 69h-16.25a4.064 4.064 0 01-4.062-4.062zM68 52.75v8.125h-8.125V52.75zm-28.437 0h-32.5a4.063 4.063 0 010-8.125h32.5a4.063 4.063 0 010 8.125zM3 8.063A4.064 4.064 0 017.063 4h65a4.064 4.064 0 014.063 4.063v24.375a4.064 4.064 0 01-4.063 4.062h-65A4.064 4.064 0 013 32.438zm65 4.063v16.25H11.125V12.125z"
        transform="translate(-3 -4)"
      ></path>
    </svg>
  );
}

export default EducationalOfficesNotFoundIcon;
