import React from "react";

function LocationIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 12.017 16.195"
    >
      <g transform="translate(-47.98 .1)">
        <g data-name="Group 22271" transform="translate(48.08)">
          <g
            fill="#177ba3"
            stroke="#177ba3"
            strokeWidth="0.2"
            data-name="Group 22270"
          >
            <path
              d="M5.909 0A5.915 5.915 0 000 5.909a6.817 6.817 0 00.5 2.275 9.528 9.528 0 00.683 1.277L5.235 15.6a.771.771 0 001.346 0l4.052-6.139a9.489 9.489 0 00.687-1.278 6.815 6.815 0 00.5-2.275A5.915 5.915 0 005.909 0zm4.767 7.914a8.729 8.729 0 01-.622 1.164l-4.052 6.139c-.08.121-.105.121-.185 0L1.765 9.078a8.734 8.734 0 01-.622-1.165 6.188 6.188 0 01-.447-2 5.213 5.213 0 1110.427 0 6.193 6.193 0 01-.448 2.001z"
              data-name="Path 16415"
            ></path>
            <path
              d="M5.909 2.781a3.128 3.128 0 103.128 3.128 3.132 3.132 0 00-3.128-3.128zm0 5.561a2.433 2.433 0 112.433-2.433 2.436 2.436 0 01-2.433 2.433z"
              data-name="Path 16416"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LocationIcon;
