import React from "react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";

const SearchByPage = ({ getDataByPage, pageNumber, setPageNumber }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <div className="flex gap-8 items-center  w-fit ">
      <button
        type="button"
        onClick={getDataByPage}
        className="bg-[#177BA3] text-white px-8 py-3 rounded-md"
      >
        {selectedContent[localizationKeys.searchByPageNumber]}
      </button>
      <input
        type="text"
        className="w-12 h-10 border-2 border-[#177BA3] text-center rounded-md "
        placeholder="number"
        value={pageNumber}
        onChange={(e) => setPageNumber(e.target.value)}
      />
    </div>
  );
};

export default SearchByPage;
