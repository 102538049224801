import React, { useEffect } from "react";
import useQueryParams from "../../../hooks.js/useQueryParams";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import {
  Dropdown,
  Form,
  Button,
  Segment,
  Header,
  Grid,
} from "semantic-ui-react";
import localizationKeys from "../../../localization/localization-keys";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";

const SchoolsFilter = ({ run, setQueryParams, queryParams, handleReset, setIsModalOpen }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const { run: getCities, data: cities } = useAxios({});
  const { run: getEducationalLevels, data: educationalLevels } = useAxios({});
  const { run: getEducationalCenters, data: educationalCenters } = useAxios({});

  useEffect(() => {
    getCities(authAxios.get(`${api.city.all}`));
    getEducationalLevels(authAxios.get(`${api.educationalLevels.all}`));
    getEducationalCenters(
      authAxios.get(`${api.educationalOffices.notPaginated}`)
    );
  }, [getCities, getEducationalLevels, getEducationalCenters]);

  const handleFilterChange = (field, value) => {
    setQueryParams({ [field]: value });
  };

  return (
    <Segment
      padded="very"
      style={{ background: "#f9f9f9", borderRadius: "10px" }}
    >
      <div className="flex justify-between items-center"></div>
      <Header as="h2" dividing>
        {selectedContent[localizationKeys.schoolFilters]}
      </Header>
      <Form>
        <Grid stackable>
          {/* City Filter */}
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <label>{selectedContent[localizationKeys.city]}</label>
                <Dropdown
                  placeholder={selectedContent[localizationKeys.selectCities]}
                  fluid
                  selection
                  multiple
                  options={cities?.data.map((city) => ({
                    key: city._id,
                    text: city.name,
                    value: city._id,
                  }))}
                  onChange={(e, { value }) => handleFilterChange("city", value)}
                />
              </Form.Field>
            </Grid.Column>

            {/* education level filter */}
            <Grid.Column>
              <Form.Field>
                <label>
                  {selectedContent[localizationKeys.educationLevels]}
                </label>
                <Dropdown
                  placeholder={selectedContent[localizationKeys.selectLevel]}
                  fluid
                  selection
                  multiple
                  options={educationalLevels?.data.map((city) => ({
                    key: city._id,
                    text: city.name,
                    value: city._id,
                  }))}
                  onChange={(e, { value }) =>
                    handleFilterChange("education_levels", value)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            {/* Gender Filter */}
            <Grid.Column>
              <Form.Field>
                <label>
                  {selectedContent[localizationKeys.StudentsGender]}
                </label>
                <Dropdown
                  placeholder={selectedContent[localizationKeys.selectGender]}
                  fluid
                  multiple
                  selection
                  options={[
                    { key: "بنين", text: "بنين", value: "بنين" },
                    { key: "بنات", text: "بنات", value: "بنات" },
                  ]}
                  onChange={(e, { value }) =>
                    handleFilterChange("students_gender", value)
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>
                  {selectedContent[localizationKeys.educationStage]}
                </label>
                <Dropdown
                  placeholder={selectedContent[localizationKeys.selectStage]}
                  fluid
                  selection
                  multiple
                  options={educationalLevels?.data.map((city) => ({
                    key: city._id,
                    text: city.name,
                    value: city._id,
                  }))}
                  onChange={(e, { value }) =>
                    handleFilterChange("education_levels", value)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          {/* Start Date and End Date Filters */}
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input
                label={selectedContent[localizationKeys.startDate]}
                type="date"
                value={queryParams?.start_date}
                onChange={(e) =>
                  handleFilterChange("start_date", e.target.value)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label={selectedContent[localizationKeys.endDate]}
                type="date"
                value={queryParams?.end_date}
                onChange={(e) => handleFilterChange("end_date", e.target.value)}
              />
            </Grid.Column>
          </Grid.Row>

          {/* Start Date and End Date for material Filters */}
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input
                label={selectedContent[localizationKeys.materialStartDate]}
                type="date"
                value={queryParams?.material_start_date}
                onChange={(e) =>
                  handleFilterChange("material_start_date", e.target.value)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label={selectedContent[localizationKeys.materialEndDate]}
                type="date"
                value={queryParams?.material_end_date}
                onChange={(e) =>
                  handleFilterChange("material_end_date", e.target.value)
                }
              />
            </Grid.Column>
          </Grid.Row>
          {/* School Type Filter */}
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <label>{selectedContent[localizationKeys.SchoolType]}</label>
                <Dropdown
                  placeholder={
                    selectedContent[localizationKeys.selectSchoolType]
                  }
                  fluid
                  selection
                  options={[
                    { key: "أهلية", text: "أهلية", value: "أهلية" },
                    { key: "خاصة", text: "خاصة", value: "خاصة" },
                  ]}
                  multiple
                  onChange={(e, { value }) =>
                    handleFilterChange("school_type", value)
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>
                  {selectedContent[localizationKeys.educationCenters]}
                </label>
                <Dropdown
                  placeholder={selectedContent[localizationKeys.selectLevel]}
                  fluid
                  selection
                  multiple
                  options={educationalCenters?.data.map((center) => ({
                    key: center._id,
                    text: center.name,
                    value: center._id,
                  }))}
                  onChange={(e, { value }) =>
                    handleFilterChange("education_centers", value)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button
            color="primary"
            onClick={()=> setIsModalOpen(false)}
            style={{ marginLeft: "0.5rem" }}
          >
            {selectedContent[localizationKeys.filter]}
          </Button>
          <Button
            color="grey"
            onClick={handleReset}
            style={{ marginLeft: "0.5rem" }}
          >
            {selectedContent[localizationKeys.reset]}
          </Button>
        </div>
      </Form>
    </Segment>
  );
};

export default SchoolsFilter;
