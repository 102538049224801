import React from "react";

function CVIcon({ className, size = "1em" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 15 20.013"
    >
      <g transform="translate(-64.129)">
        <g data-name="Group 22477" transform="translate(64.129)">
          <g data-name="Group 22476">
            <path
              fill="#177ba3"
              d="M78.522 0H64.736a.607.607 0 00-.607.607v18.8a.607.607 0 00.607.607H75.13a.914.914 0 00.65-.269l3.08-3.079a.914.914 0 00.269-.65V.607A.607.607 0 0078.522 0zm-.078 16.25l-2.763 2.763v-2.426a.02.02 0 01.02-.02h1.567a.293.293 0 100-.586H75.7a.607.607 0 00-.607.607v2.84H64.736a.02.02 0 01-.02-.02V.607a.02.02 0 01.02-.02h13.786a.02.02 0 01.02.02v15.407a.331.331 0 01-.097.236z"
              data-name="Path 17906"
              transform="translate(-64.129)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22479" transform="translate(66.636 2.82)">
          <g data-name="Group 22478">
            <path
              fill="#177ba3"
              d="M130.636 75.033a.293.293 0 00-.415 0 .8.8 0 01-1.377-.561v-.94a.8.8 0 011.377-.561.293.293 0 00.419-.41 1.389 1.389 0 00-2.383.971v.94a1.389 1.389 0 002.383.971.293.293 0 00-.004-.41z"
              data-name="Path 17907"
              transform="translate(-128.258 -72.147)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22481" transform="translate(69.456 2.82)">
          <g data-name="Group 22480">
            <path
              fill="#177ba3"
              d="M202.972 72.155a.293.293 0 00-.365.2l-.816 2.72-.816-2.72a.293.293 0 00-.562.169l.94 3.133a.293.293 0 00.281.209h.313a.293.293 0 00.281-.209l.94-3.133a.293.293 0 00-.196-.369z"
              data-name="Path 17908"
              transform="translate(-200.401 -72.143)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22483" transform="translate(73.216 3.447)">
          <g data-name="Group 22482">
            <path
              fill="#177ba3"
              d="M298.769 88.177h-1.88a.293.293 0 000 .586h1.88a.293.293 0 000-.586z"
              data-name="Path 17909"
              transform="translate(-296.596 -88.177)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22485" transform="translate(73.216 5.013)">
          <g data-name="Group 22484">
            <path
              fill="#177ba3"
              d="M300.022 128.258h-3.133a.293.293 0 000 .586h3.133a.293.293 0 100-.586z"
              data-name="Path 17910"
              transform="translate(-296.595 -128.258)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22487" transform="translate(68.516 9.087)">
          <g data-name="Group 22486">
            <path
              fill="#177ba3"
              d="M182.288 232.468h-5.64a.293.293 0 100 .586h5.64a.293.293 0 100-.586z"
              data-name="Path 17911"
              transform="translate(-176.355 -232.468)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22489" transform="translate(68.516 10.654)">
          <g data-name="Group 22488">
            <path
              fill="#177ba3"
              d="M184.166 272.548h-7.52a.293.293 0 100 .586h7.52a.293.293 0 000-.586z"
              data-name="Path 17912"
              transform="translate(-176.353 -272.548)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22491" transform="translate(66.636 10.654)">
          <g data-name="Group 22490">
            <path
              fill="#177ba3"
              d="M129.178 272.548h-.627a.293.293 0 100 .586h.627a.293.293 0 100-.586z"
              data-name="Path 17913"
              transform="translate(-128.258 -272.548)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22493" transform="translate(66.636 12.22)">
          <g data-name="Group 22492">
            <path
              fill="#177ba3"
              d="M137.95 312.629h-9.4a.293.293 0 100 .586h9.4a.293.293 0 000-.586z"
              data-name="Path 17914"
              transform="translate(-128.257 -312.629)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22495" transform="translate(75.409 13.787)">
          <g data-name="Group 22494">
            <path
              fill="#177ba3"
              d="M353.626 352.709H353a.293.293 0 000 .586h.627a.293.293 0 100-.586z"
              data-name="Path 17915"
              transform="translate(-352.706 -352.709)"
            ></path>
          </g>
        </g>
        <g data-name="Group 22497" transform="translate(66.636 13.787)">
          <g data-name="Group 22496">
            <path
              fill="#177ba3"
              d="M136.07 352.709h-7.52a.293.293 0 100 .586h7.52a.293.293 0 000-.586z"
              data-name="Path 17916"
              transform="translate(-128.257 -352.709)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CVIcon;
