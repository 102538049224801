import { Button } from "semantic-ui-react";

const TableButton = ({ children, ...props }) => {
  return (
    <Button
      basic
      className="mx-auto flex  w-full items-center justify-center rounded-md px-2  py-2 hover:shadow-md"
      size="small"
      color="blue"
      {...props}
    >
      {children}
    </Button>
  );
};

export default TableButton;
