import classNames from "classnames";
import { AiFillEye } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { generatePath, Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import routes from "../../routes";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { useLanguage } from "../../lib/language-context";
import TableButton from "./table-button";
import { useState } from "react";
import ResumeModal from "./profile/resume-modal";

const InstructorRow = ({ instructor, modalOpen }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [modal, setModal] = useState({ open: false, loaded: false });

  return (
    <div
      key={instructor._id}
      className="table-header mb-3 cursor-default grid-cols-7 gap-4 text-black bg-white"
    >
      <Link
        to={{
          pathname: routes.instructors.profile(instructor._id),
        }}
        className="overflow-hidden text-ellipsis whitespace-nowrap text-black hover:underline"
      >
        {instructor.name?.[lang] ||
          selectedContent[localizationKeys.instructorName]}
      </Link>
      <div className="mx-auto whitespace-nowrap">
        {instructor.education_levels?.length &&
          instructor.education_levels?.[0].name}
        {instructor.education_levels?.length > 1 ? "..." : ""}
      </div>
      <div className="mx-auto whitespace-nowrap">
        {instructor.subject?.length && instructor.subject?.[0].name}
        {instructor.subject?.length > 1 ? "..." : ""}
      </div>
      <div className="mx-auto whitespace-nowrap">{instructor.country}</div>

      <div>
        <TableButton
          onClick={() => setModal((state) => ({ ...state, open: true }))}
          disabled={!instructor.resume_link}
        >
          <AiFillEye className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
          <span>{selectedContent[localizationKeys.view]}</span>
        </TableButton>
      </div>
      <a
        href={instructor.resume_link}
        target="_blank"
        rel="noopener noreferrer"
        download={(instructor?.name?.en || "CV") + ".pdf"}
      >
        <TableButton disabled={!instructor.resume_link}>
          <BsDownload className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
          <span>{selectedContent[localizationKeys.download]}</span>
        </TableButton>
      </a>

      <Link
        to={{
          pathname: routes.instructors.profile(instructor._id),
          state: { list: "instructors" },
        }}
        className="mx-auto"
      >
        <AiFillEye className="mx-auto cursor-pointer fill-black hover:fill-primary-900" />
      </Link>

      <ResumeModal modal={modal} setModal={setModal} instructor={instructor} />
    </div>
  );
};

export default InstructorRow;
