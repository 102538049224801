import React from "react";
import { Formik } from "formik";
import { Modal, Form, Button } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import FormikInput from "../../components/formik/formik-input";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import toast from "react-hot-toast";
import FormikDropdown from "../../components/formik/formik-dropdown";
import FormikCheckbox from "../../components/formik/formik-checkbox";
import * as Yup from "yup";

const AddEducationalOffice = ({
  isOpen,
  onClose,
  reload,
  isEditingEducationalOffices,
  educationalOffice,
  cities,
}) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const {
    run: postEducationalOffice,
    isLoading: loadPostingEducationalOffice,
  } = useAxios({});
  const {
    run: editEducationalOffice,
    isLoading: loadEditingEducationalOffice,
  } = useAxios({});

  const [gender, setGender] = React.useState("بنين");

  const addNewEducationalCenterSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(selectedContent[localizationKeys.required]),
    city: Yup.string()
      .trim()
      .required(selectedContent[localizationKeys.required]),
  });

  React.useEffect(() => {
    if (educationalOffice) setGender(educationalOffice?.gender);
  }, [educationalOffice]);

  const handleModalClose = () => {
    onClose();
    setGender("بنين");
  };

  const onSubmit = (values) => {
    if (isEditingEducationalOffices) {
      editEducationalOffice(
        authAxios.put(
          `${api.educationalOffices.all}/${educationalOffice?._id}`,
          {
            ...values,
            gender,
          }
        )
      )
        .then(() => {
          reload();
          handleModalClose();
          toast.success(selectedContent[localizationKeys.editedSuccessfully]);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
    } else {
      postEducationalOffice(
        authAxios.post(api.educationalOffices.all, { ...values, gender })
      )
        .then(() => {
          reload();
          handleModalClose();
          toast.success(selectedContent[localizationKeys.createdSuccessfully]);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="tiny"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="max-w-screen-2xl "
    >
      <h3 className="text-lg text-center font-extrabold pt-6">
        {isEditingEducationalOffices
          ? selectedContent[localizationKeys.editEducationalOffice]
          : selectedContent[localizationKeys.addEducationalOffice]}
      </h3>
      <Modal.Content>
        <Formik
          initialValues={{
            name: educationalOffice?.name ?? "",
            city: educationalOffice?.city?._id ?? "",
            // gender: "",
          }}
          onSubmit={onSubmit}
          validationSchema={addNewEducationalCenterSchema}
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              loading={
                loadEditingEducationalOffice || loadPostingEducationalOffice
              }
            >
              <div className="grid grid-cols-2 items-center justify-center w-56">
                <span className="text-center font-bold mt-4">
                  {selectedContent[localizationKeys.educationalOfficeName]}
                </span>
                <FormikInput
                  fluid
                  name="name"
                  className="w-64"
                  placeholder={selectedContent[localizationKeys.searchSpec]}
                />
              </div>
              <div className="grid grid-cols-2 items-center justify-center w-56">
                <span className="text-center font-bold mt-4">
                  {selectedContent[localizationKeys.city]}
                </span>
                <FormikDropdown
                  className="w-64"
                  options={cities}
                  name="city"
                  onChangeCallback={(e) => {}}
                />
              </div>
              <div className="grid grid-cols-2 items-center justify-center w-56">
                <span className="text-center font-bold mt-4">
                  {selectedContent[localizationKeys.gender]}
                </span>
                <div className="grid grid-cols-2 items-center justify-center">
                  <div className="flex items-center">
                    <FormikCheckbox
                      checked={gender === "بنين"}
                      className="fill-primary"
                      name="gender1"
                      radio
                      value={gender === "بنين"}
                      onChangeCallback={(e) => {
                        setGender("بنين");
                      }}
                    />
                    <span
                      className="mt-5 font-bold hover:cursor-pointer"
                      onClick={() => setGender("بنين")}
                    >
                      {selectedContent[localizationKeys.male]}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <FormikCheckbox
                      checked={gender === "بنات"}
                      className="rtl:mr-16 ltr:ml-16 mt-2"
                      name="gender2"
                      radio
                      value={gender === "بنات"}
                      onChangeCallback={(e) => {
                        setGender("بنات");
                      }}
                    />
                    <span
                      className="mt-5 font-bold hover:cursor-pointer"
                      onClick={() => setGender("بنات")}
                    >
                      {selectedContent[localizationKeys.female]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center rtl:float-left ltr:float-right pb-8 mt-6">
                <Button
                  type="button"
                  className="rounded-md bg-white "
                  onClick={() => handleModalClose()}
                >
                  {selectedContent[localizationKeys.cancel]}
                </Button>
                <Button
                  type="submit"
                  primary
                  className="rounded-md w-28"
                  disabled={
                    loadEditingEducationalOffice || loadPostingEducationalOffice
                  }
                >
                  {isEditingEducationalOffices
                    ? selectedContent[localizationKeys.edit]
                    : selectedContent[localizationKeys.create]}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default AddEducationalOffice;
