import React, { useState } from "react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { Button, Loader, Modal } from "semantic-ui-react";
import toast from "react-hot-toast";
import localizationKeys from "../../localization/localization-keys";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import { FaEye } from "react-icons/fa";
import { truncateString } from "../../utils/string";
import useFilter from "../../lib/use-filter";
import routes from "../../routes";
import { useHistory, useLocation } from "react-router-dom";
import PaginationComponent from "../../components/shared/pagination";
import SchoolsNotFoundIcon from "../../assets/icons/school-notFound-icon";
import SearchByPage from "../../components/shared/SearchByPage";
import SchoolsFilter from "./components/school-filter";
import { BsSliders } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useQueryParams from "../../hooks.js/useQueryParams";

const AllSchoolsPage = () => {
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [isDownload, setIsDownload] = React.useState(false);

  const { run, isLoading } = useAxios({ data: [] });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [limit] = React.useState("8");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);

  const [page, setPage] = useFilter("page", 1);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);
  const [pageNumber, setPageNumber] = useState(1);
  const getDataByPage = async () => {
    setPage(pageNumber);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    start_date: "", // done
    end_date: "", // done
    students_gender: "", // done
    city: "", // done
    education_levels: [], // done
    school_type: "", // done
    education_centers: [], // done
    material_start_date: "", // done
    material_end_date: "", // done
    education_stage: "", // done
  });
  const handleReset = () => {
    setQueryParams({
      start_date: "",
      end_date: "",
      students_gender: "",
      city: "",
      education_levels: [],
      school_type: "",
      education_centers: [],
      material_start_date: "",
      material_end_date: "",
      education_stage: "",
    });
  };

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      run(authAxios.get(`${api.schools.all + location.search}`))
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
  }, [limit, parsed?.page, run, location.search]);
  const DownloadExellFile = async () => {
    try {
      setIsDownload(true);
      await authAxios
        .get(api.schools.excel + location.search, { responseType: "blob" })
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          setIsDownload(false);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setIsDownload(false);
    }
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="relative p-4 w-[70%]"
      >
        <Modal.Content className="relative w-full">
          <SchoolsFilter
            run={run}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            handleReset={handleReset}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal.Content>
      </Modal>
      <div className="flex flex-col lg:flex-row flex-wrap lg:justify-between gap-2 lg:items-center mb-8">
        <div>
          <Button
            basic
            className="flex flex-row flex-nowrap items-center gap-2"
            onClick={() => setIsModalOpen(true)}
          >
            <span>
              <BsSliders size={20} className="rotate-90 hover:cursor-pointer" />
            </span>
            <span className="font-bold md:inline">
              {selectedContent[localizationKeys.schoolFilters]}
            </span>
          </Button>
        </div>
        {location.search?.length > 7 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="grey"
              onClick={handleReset}
              style={{ marginLeft: "0.5rem" }}
            >
              {selectedContent[localizationKeys.reset]}
            </Button>
          </div>
        ) : null}
      </div>
      <div className="relative mb-10 bg-white px-2 py-1 rounded-xl min-h-[36rem] overflow-x-auto">
        {isLoading ? (
          <Loader active={true} />
        ) : data?.data?.length > 0 ? (
          <>
            {/* Table Headers */}
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-10 my-4 bg-gray-100 rounded-md text-lightGrey p-2 min-w-[800px]">
              <div>{selectedContent[localizationKeys.schoolName]}</div>
              <div>{selectedContent[localizationKeys.educationalLevels]}</div>
              <div className="lg:ltr:ml-4 lg:rtl:mr-4 ">
                {selectedContent[localizationKeys.educationalOffice]}
              </div>
              <div className="lg:ltr:ml-4 lg:rtl:mr-4 whitespace-nowrap">
                {selectedContent[localizationKeys.city]}
              </div>
              <div>{selectedContent[localizationKeys.type]}</div>
              <div>{selectedContent[localizationKeys.studentCategory]}</div>
              <div>{selectedContent[localizationKeys.RegistrationDate]}</div>
              <div className="col-span-2 lg:ltr:ml-4 lg:rtl:mr-4">
                {selectedContent[localizationKeys.MaterialDownloadDate]}
              </div>
              <div>{selectedContent[localizationKeys.watch]}</div>
            </div>

            {/* Table Rows */}
            {data?.data?.map((d) => (
              <div
                className="border-b border-b-gray-200 w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-10 py-2 items-center hover:rounded-lg transition-all duration-300 ease-in hover:-translate-y-1 hover:shadow-sm min-w-[800px]"
                key={d._id}
              >
                <div>
                  {lang === "ar"
                    ? truncateString(d?.name?.ar, 30)
                    : truncateString(d?.name?.en, 25)}
                </div>
                <div>
                  {d?.education_levels.length ? (
                    <>
                      <span>{d?.education_levels[0]?.name}</span>
                      {d?.education_levels[1]?.name && (
                        <span> - {d?.education_levels[1]?.name}</span>
                      )}
                      {d?.education_levels[2]?.name && <span>..</span>}
                    </>
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div className="lg:mx-auto">
                  {d?.education_centers ? (
                    <span>{d?.education_centers?.[0]?.name}</span>
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div className="lg:ltr:ml-4 lg:rtl:mr-4">
                  {d?.city ? (
                    <span>{d?.city?.[0]?.name}</span>
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div>
                  {d?.school_type ? (
                    <span>{d?.school_type}</span>
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div>
                  {d?.students_gender?.length ? (
                    <>
                      <span>{d?.students_gender[0]}</span>
                      {d?.students_gender[1] && (
                        <span>- {d?.students_gender[1]}</span>
                      )}
                    </>
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div className="lg:ltr:ml-4 lg:rtl:mr-4">
                  <span>{d?.createdAt?.split("T")[0]}</span>
                </div>
                <div className="col-span-2 lg:ltr:ml-4 lg:rtl:mr-4">
                  {d?.materialDownloadDate ? (
                    d?.materialDownloadDate.split("T")[0]
                  ) : (
                    <span>--</span>
                  )}
                </div>
                <div>
                  <FaEye
                    className="text-center hover:cursor-pointer"
                    size={16}
                    onClick={() => history.push(routes.schools.profile(d._id))}
                  />
                </div>
              </div>
            ))}

            {/* Pagination and Search and download file excel */}
            <div className="flex  gap-5 items-center justify-around mt-8">
              <div className="exel-sheet">
                <button
                  disabled={isLoading}
                  className="bg-[#177BA3] text-white px-8 py-3 rounded-md w-[10rem]"
                  onClick={DownloadExellFile}
                >
                  {isDownload ? (
                    <AiOutlineLoading3Quarters className="animate-spin mx-auto" />
                  ) : (
                    selectedContent[localizationKeys.ExportFile]
                  )}
                </button>
              </div>
              <div className="flex gap-5 items-center justify-center mt-5 lg:ltr:ml-4 lg:rtl:mr-4">
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  className="rtl:flex-row-reverse"
                />
                <SearchByPage
                  getDataByPage={getDataByPage}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="bg-white px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
            <div className="flex-col">
              <SchoolsNotFoundIcon
                size="80"
                className="justify-center mx-auto text-center my-4"
              />
              <span className="text-xl text-primary text-center">
                {selectedContent[localizationKeys.schoolsNotFound]}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllSchoolsPage;
