import classNames from "classnames";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Modal, Search } from "semantic-ui-react";
import { authAxios } from "../../../config/axios-config";
import { useAppMedia } from "../../../hooks.js/use-media-query";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";

import styles from "./subject-filter-modal.module.css";
const SubjectFilterModal = ({
  states: { filterModal, setFilterModal, subjectList, setSubjectList },
  setSearchParams,
  searchParams,
}) => {
  const [lang] = useLanguage();
  const history = useHistory();
  const location=useLocation()
  const [search, setSearch] = useState("");
  const selectedContent = content[lang];
  const { isTablet } = useAppMedia();
  const filterModalAction = {
    open: () =>
      setFilterModal({
        ...filterModal,
        open: true,
      }),
    close: () => {
      setFilterModal({ open: false });
    },
    cancel: () => {
      setSearchParams(
        (prev) =>
          filterModal.initSelect || {
            subjects: [],
            cities: [...prev.cities],
          }
      );
      setFilterModal({ open: false });
    },
    confirm: () => {
      if (searchParams.subjects?.length < 1) {
        history.replace({ search: `` });
      } else if (location.search?.includes("cities")) {
        const citiesUrl = location.search.split("cities")[1];
        history.replace({
          search: `?cities${citiesUrl}&subjects${
            searchParams.subjects?.length === 1 ? "[]" : ""
          }=${searchParams.subjects?.join(",")}`,
        });
      } else {
        history.replace({
          search: `?subjects${
            searchParams.subjects?.length === 1 ? "[]" : ""
          }=${searchParams.subjects?.join(",")}`,
        });
      }
      filterModalAction.close();
    },
  };

  const addSubjToUrl = (subject, e) => {
    e.stopPropagation();

    if (!searchParams.subjects?.includes(subject._id) && e.target.checked) {
      searchParams.subjects?.push(subject._id);
    } else if (
      searchParams.subjects?.includes(subject._id) &&
      !e.target.checked
    ) {
      setSearchParams({
        cities: [...searchParams.cities],
        subjects: searchParams.subjects?.filter((sub) => sub !== subject._id),
      });
    }
  };

  useEffect(() => {
    authAxios
      .get("/subjects", { params: { name: search || null } })
      .then((res) => setSubjectList(res.data.data));
  }, [search]);

  useEffect(() => {
    authAxios.get("/subjects").then((res) => setSubjectList(res.data.data));
  }, []);

  const FilterSearch = () => (
    <Search
      autoFocus="autoFocus"
      input={{
        icon: "search",
        iconPosition: "left",
      }}
      open={false}
      className={classNames(styles.field, {
        "my-3 w-full": isTablet,
      })}
      value={search}
      onSearchChange={(e, data) => setSearch(data?.value)}
    />
  );
  return (
    <Modal
      onClose={filterModalAction.close}
      onOpen={filterModalAction.open}
      open={filterModal.open}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      className="relative min-h-[10rem] max-w-xl"
    >
      <Modal.Content className="mb-0 pb-0">
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full flex-row items-center justify-between gap-1">
            <p className="whitespace-nowrap">
              {selectedContent[localizationKeys.searchBySubject]}
            </p>
            {!isTablet && <FilterSearch />}
            <Button
              className={classNames("bg-transparent p-0", {
                "mr-auto": lang === "ar",
                "ml-auto": lang !== "ar",
              })}
              onClick={() =>
                setSearchParams({
                  subjects: [],
                  cities: [],
                })
              }
            >
              {selectedContent[localizationKeys.reset]}
            </Button>
          </div>
          {isTablet && <FilterSearch />}
          <div className="mt-5 flex flex-row flex-wrap gap-4">
            {subjectList?.length > 0 &&
              subjectList.map((subject, index) => (
                <label key={index} className="hover:cursor-pointer">
                  <input
                    type="checkbox"
                    tabIndex="0"
                    className="peer hidden"
                    defaultChecked={searchParams.subjects?.includes(
                      subject._id
                    )}
                    // onChange={(e)=>}
                    onClick={(e) => {
                      addSubjToUrl(subject, e);
                      // e.stopPropagation();
                      // if (selected?.includes(subject._id) && !e.target.checked) {
                      //   setSelected(
                      //     [...selected].filter((sub) => sub !== subject._id)
                      //   );
                      // } else if (e.target.checked) {
                      //   setSelected([...selected, subject._id]);
                      // }
                    }}
                  />
                  <div className="text-primary rounded-full px-4 py-1 text-lg outline outline-1 outline-primary-900 transition-[outline] hover:bg-primary-900 hover:bg-opacity-90 hover:text-white peer-checked:bg-primary-900 peer-checked:text-white">
                    {subject.name}
                  </div>
                </label>
              ))}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className="my-4 flex flex-col justify-end gap-1 rounded-lg md:flex-row">
        <Button
          content={selectedContent[localizationKeys.cancel]}
          basic
          color="blue"
          className="w-full min-w-min md:w-1/4"
          onClick={filterModalAction.cancel}
        />
        <Button
          content={selectedContent[localizationKeys.filter]}
          color="blue"
          className="w-full min-w-min md:w-1/4"
          onClick={filterModalAction.confirm}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SubjectFilterModal;
