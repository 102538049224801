import React from "react";

function EducationalOfficesIcon({className, size = '1em'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 16"
    >
      <path
        // fill="#6b55bc"
        fillRule="evenodd"
        d="M12 20H4a1 1 0 010-2h8a1 1 0 010 2zm3-5a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1zm4 1v2h-2v-2zm-7 0H4a1 1 0 010-2h8a1 1 0 010 2zM3 5a1 1 0 011-1h16a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1zm16 1v4H5V6z"
        transform="translate(-3 -4)"
      ></path>
    </svg>
  );
}

export default EducationalOfficesIcon;
