import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import { IoMdClose } from "react-icons/io";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { MdOutlineHideImage } from "react-icons/md";
import { MdOutlineSlideshow } from "react-icons/md";
import Swal from "sweetalert2";
import localizationKeys from "../../../localization/localization-keys";
import content from "../../../localization/content";
import { useLanguage } from "../../../lib/language-context";
import { Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";

export default function ViewAllImages() {
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const [imageUrls, setImageUrls] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const params = useParams();

  const fetchAllImages = async () => {
    try {
      setIsLoading(true);
      const response = await authAxios.get(api.album.viewAllAlbum(params?.id));
      setImageUrls(response?.data?.school_albums);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleImageChange = (index) => {
    const image = imageUrls[index]?.fileLink;
    setCurrentImage(image);
    setShowImage(true);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(nextIndex);
    setCurrentImage(imageUrls[nextIndex]?.fileLink);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(prevIndex);
    setCurrentImage(imageUrls[prevIndex]?.fileLink);
  };

  const toggleImageVisibility = async (id) => {
    try {
      let image = imageUrls.find((img) => img._id === id);
      if (!image) return;
      if (image.isVisible) {
        const result = await Swal.fire({
          title: selectedContent[localizationKeys.toggleVisibilityTitle],
          text: selectedContent[localizationKeys.toggleVisibilityText],
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText:
            selectedContent[localizationKeys.toggleVisibilityConfirm],
          cancelButtonText:
            selectedContent[localizationKeys.toggleVisibilityCancel],
        });
        if (!result.isConfirmed) {
          setIsLoading(false);
          return;
        }
      }
      setIsLoading(true);
      const response = await authAxios.patch(
        api.album.toggleImageVisibility(id),
        {
          isVisible: !image.isVisible,
        }
      );
      setImageUrls((prevUrls) =>
        prevUrls.map((img) =>
          img._id === id
            ? { ...img, isVisible: response?.data?.data?.isVisible }
            : img
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Error toggling image visibility:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllImages();
  }, [params?.id]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") setShowImage(false);
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, imageUrls]);
  return (
    <section className="p-4">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loader active={true} size="small" className="mt-4" />
        </div>
      ) : (
        <>
          <div
            onClick={() => history.goBack()}
            className="my-4 w-fit cursor-pointer rounded-full border border-primary-900 px-3 py-3 text-primary-900 transition-all duration-300 hover:bg-primary-900 hover:text-white rtl:rotate-180"
          >
            <IoArrowBackOutline className="flex items-center justify-center " />
          </div>
          <div
            className={`flex flex-wrap items-center rounded-md p-4 ${
              imageUrls?.length > 0 ? "border border-gray-200" : ""
            } `}
          >
            {imageUrls?.length > 0 && (
              <div className="flex w-full flex-wrap items-center">
                <div
                  className={`fixed inset-0 z-[200] bg-black bg-opacity-75 ${
                    showImage ? "visible" : "hidden"
                  }`}
                >
                  <button
                    onClick={() => setShowImage(false)}
                    className="absolute right-6 top-4 rounded-md bg-red-400 p-3 text-white hover:bg-red-500"
                  >
                    <IoMdClose />
                  </button>
                  <button
                    onClick={handleNext}
                    className="absolute right-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
                  >
                    <FaAngleRight />
                  </button>
                  <button
                    onClick={handlePrev}
                    className="absolute left-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
                  >
                    <FaAngleLeft />
                  </button>
                  <div className="mx-auto flex items-center justify-center">
                    <img
                      className="h-[100vh] w-[90vw] rounded-lg border border-gray-300 object-contain shadow-lg"
                      src={currentImage}
                      alt="Expanded View"
                    />
                  </div>
                </div>
                {imageUrls?.map((url, index) => (
                  <>
                    <div
                      onClick={() => handleImageChange(index)}
                      key={url?._id}
                      className="w-full cursor-pointer p-4 sm:w-1/3 md:w-1/4"
                    >
                      <div
                        className={`group relative my-4 mx-auto overflow-hidden rounded-lg p-4 shadow-lg transition-transform`}
                      >
                        <div
                          className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-white text-2xl z-10 ${
                            url?.isVisible ? "hidden" : "visible"
                          }`}
                        >
                          <MdOutlineHideImage size={20} />
                        </div>
                        <div
                          className={`inset-0 absolute ${
                            url?.isVisible ? "" : "bg-black bg-opacity-75"
                          }`}
                        ></div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleImageVisibility(url?._id);
                          }}
                          role="button"
                          aria-label="Toggle Image Visibility"
                          className="absolute inset-x-0 bottom-0 flex translate-y-full cursor-pointer items-center justify-between bg-red-100 py-2 px-2 duration-300 hover:bg-red-300 group-hover:translate-y-0"
                        >
                          <p className="font-bold">
                            {url?.isVisible
                              ? selectedContent[localizationKeys.hideImage]
                              : selectedContent[localizationKeys.showImage]}
                            :
                          </p>
                          {url?.isVisible ? (
                            <MdOutlineHideImage size={20} />
                          ) : (
                            <MdOutlineSlideshow size={20} />
                          )}
                        </div>
                        <img
                          src={url?.fileLink}
                          alt={`Uploaded ${index + 1}`}
                          className="w-full rounded-md bg-cover bg-center"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}
